import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, useSearchParams } from "react-router-dom";
import "./assets/css/line-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contact from "./components/contact/contact";
import MeetingRequestForm from "./components/meetingRequestForm";
import ThankYouPage from "./components/ThankYouPage/thankYouPage";
import Login from "./components/Login/login";
import Registration from "./components/Registration/registration";
import LoginOTP from "./components/Login/loginOTP";
import Dashboardcontent from "./components/Dashborad";
import Profile from "./components/My Profile/Profile";
import Queues from "./My Queues/Queues";
import ImageCrop from "./My Queues/ImageCrop";
import AllMeeting from "./components/Dashborad/AllMeeting";
import DoctorRegistration from "./components/Registration/DoctorRegistration";
import AdminDashboard from "./components/Admin/Dashboard/Dashboard";
import ViewMeeting from "./components/Admin/Dashboard/ViewMeeting";
import PatientDashboard from "./components/Dashborad/PatientDashboard";
import ChangePassword from "./components/My Profile/ChangePassword";
import DoctorDashboard from "./components/DoctorDashboard/DoctorDashboard";
import AdminLogin from "./components/Admin/Login/Login";
import FailedPage from "./components/ThankYouPage/Failed";
import Consent from "./components/meetingRequestForm/Consent";
import Terms from "./components/meetingRequestForm/Terms";
import CreateUser from "./components/Admin/Dashboard/CreateUser";
import jwt_decode from "jwt-decode";
import ThankYou from "./components/meetingRequestForm/thankyou";
import NewHeader from "./components/header/newHeader";
import CreatePrescription from "./components/CreatePrescription/CreatePrescription";
import { doctorSubscriptions } from "./config/service";
import IchilovConsent from "./components/meetingRequestForm/IchilovConsent";

import DyteMeetingConatiner from "./components/Dyte/dyteMeetingContainer";

function App() {
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(true);
  const [subscriptionsList, setSubscriptionsList] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const expertise = searchParams.get("expertise");
    localStorage.setItem("expertise", expertise);
    const docId = searchParams.get("docId");
    const storagePartnerId = searchParams.get("partnerId");
    localStorage.setItem("docId", docId);
    localStorage.setItem("partnerId", storagePartnerId);
    if (location.pathname === "/thankyou") {
      setShow(false);
    }
    if (
      location.pathname === "/" ||
      location.pathname === "/login/" ||
      location.pathname === "/meetingrequest/"
    ) {
      const utm = searchParams.get("utm");
      localStorage.setItem("utm", utm);
      const collective = searchParams.get("collective");
      localStorage.setItem("collective", collective);
      const refferalId = searchParams.get("refferalId");
      if (refferalId) {
        localStorage.setItem("refferalId", refferalId);
      }
      const mode = searchParams.get("mode");
      if (mode) {
        localStorage.setItem("mode", mode);
        localStorage.setItem("uploadModal", "open");
      }
    }
  }, [location]);
  const refreshtoken = () => {
    let decodedToken = jwt_decode(localStorage.getItem("refreshToken"));
    const dateNow = new Date();
    if (decodedToken.exp < dateNow.getTime() / 1000) {
      return false;
    } else {
      return true;
    }
  };
  const getDoctorSubscriptions = async () => {
    const data = {
      userId: userId,
    };
    const response = await doctorSubscriptions(data);
    setSubscriptionsList(response?.data?.subscriptions);
    // const result = response?.data?.subscriptions;
    // result.slice();
    // setSubscriptionsList(result);
  };

  useEffect(() => {
    if (role === "doctor") {
      getDoctorSubscriptions();
    }
  }, [role]);

  return (
    <div className="App">
      {/* <MainHeader show={show} /> */}
      {!location.pathname.includes("/dyte-video-call/") &&
        !location.pathname.includes("/join") && (
          <NewHeader show={show} subscriptionsList={subscriptionsList} />
        )}

      <Routes>
        {localStorage.getItem("token") && refreshtoken() ? (
          <>
            {localStorage.getItem("role") === "admin" ? (
              <>
                <Route exact path="/" element={<AdminDashboard />} />
                <Route exact path="*" element={<AdminDashboard />} />
                <Route exact path="/login" element={<AdminDashboard />} />
                <Route
                  exact
                  path="/AdminDashboard"
                  element={<AdminDashboard />}
                />
                <Route exact path="/ViewMeeting" element={<ViewMeeting />} />
                <Route
                  exact
                  path="/meetingrequest"
                  element={<MeetingRequestForm />}
                />
                <Route exact path="/createuser" element={<CreateUser />} />
              </>
            ) : (
              <Route
                exact
                path="/"
                element={<Queues subscriptionsList={subscriptionsList} />}
              />
            )}
            <Route
              exact
              path="/"
              element={<Queues subscriptionsList={subscriptionsList} />}
            />
            <Route
              exact
              path="/login"
              element={<Queues subscriptionsList={subscriptionsList} />}
            />
            <Route
              exact
              path="*"
              element={<Queues subscriptionsList={subscriptionsList} />}
            />

            <Route
              exact
              path="/meetingrequest"
              element={<MeetingRequestForm />}
            />
            <Route exact path="/Consent" element={<Consent />} />
            <Route exact path="/ichilovConsent" element={<IchilovConsent />} />
            <Route exact path="/Terms" element={<Terms />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/thankyou" element={<ThankYouPage />} />
            <Route exact path="/failedpage" element={<FailedPage />} />
            <Route exact path="/LoginOTP" element={<LoginOTP />} />
            <Route exact path="/Dashboard" element={<Dashboardcontent />} />
            <Route exact path="/Profile" element={<Profile />} />
            <Route
              exact
              path="/Queues"
              element={<Queues subscriptionsList={subscriptionsList} />}
            />
            <Route exact path="/ImageCrop" element={<ImageCrop />} />
            <Route exact path="/AllMeeting" element={<AllMeeting />} />
            <Route exact path="/meeting/thank-you" element={<ThankYou />} />
            <Route
              exact
              path="/PatientDashboard"
              element={<PatientDashboard />}
            />
            <Route exact path="/ChangePassword" element={<ChangePassword />} />
            <Route
              exact
              path="/DoctorDashboard"
              element={<DoctorDashboard />}
            />
            <Route
              exact
              path="/create-prescription"
              element={<CreatePrescription />}
            />

            <Route
              exact
              path="/dyte-video-call/:meetingId"
              element={<DyteMeetingConatiner />}
            />
          </>
        ) : (
          <>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route
              exact
              path="/DoctorRegistration"
              element={<DoctorRegistration />}
            />
            <Route exact path="/registration" element={<Registration />} />
            <Route exact path="/referal" element={<Registration />} />
            <Route
              exact
              path="/meetingrequest"
              element={<MeetingRequestForm />}
            />
            <Route exact path="/Consent" element={<Consent />} />
            <Route exact path="/Terms" element={<Terms />} />
            <Route exact path="/vitaju" element={<AdminLogin />} />
            <Route exact path="*" element={<Login />} />
            <Route
              exact
              path="/join/:meetingId"
              element={<DyteMeetingConatiner />}
            />

            {/* <Route exact path="/hi-doc/?offer=id" element={<Registration />} /> */}
          </>
        )}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;

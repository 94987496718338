import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../My Queues/queues.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";

import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import "react-quill/dist/quill.snow.css";
import InnerSummaryComponent from "./InnerSummaryComponent";
// import crossImage from "../../assets/images/cross/crs.png";

const SummaryComponent = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onHide={() => {
          props.handleClose();
        }}
        backdrop="static"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="summaryModal doctor-summary"
      >
        <MDBCard style={{ width: "100%", height: "100%", overflowY: "auto" }}>
          <MDBCardBody>
            <InnerSummaryComponent
              handleClose={props.handleClose}
              meetingId={props.meetingId}
              updateSummaryResponce={props.updateSummaryResponce}
              prescriptionResponce={props.prescriptionResponce}
              setUpdateSummaryResponse={props.setUpdateSummaryResponse}
              userName={props.userName}
              checkIDF={props.checkIDF}
              userData={props.userData}
              doctorData={props.doctorData}
              hasMeetingType={props.hasMeetingType}
            />
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};

export default SummaryComponent;

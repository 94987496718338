import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";
import logowhite from "../../assets/images/header/logo_blue.png";
import logo from "../../assets/images/header/logo_white.png";
import top_left_bg from "../../assets/images/header/top_left.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { siteLogoutTime } from "../../config/key";
// doubleLanguage
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { monoLanguage } from "../../config/key";
localStorage.setItem("monoLanguage", monoLanguage);
const MainHeader = (props) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  // const lang = localStorage.getItem("lang");
  if (localStorage.getItem("monoLanguage") === "true") {
    document.body.dir = "rtl";
  } else {
    document.body.dir = "ltr";
  }
  let timer;

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "dblclick",
    "scroll",
    "keypress",
    "mousewheel",
    "DOMMouseScroll",
    "mouseout",
    "mouseenter",
    "mouseleave",
    "mouseup",
    "keydown",
    "keyup",
  ];

  document.body.dir = "rtl";
  let selected;
  const languageMap = {
    EN: { dir: "ltr", active: false },
    HE: { dir: "rtl", active: true },
  };
  if (["EN", "HE"].includes(localStorage.getItem("i18nextLng"))) {
    selected = localStorage.getItem("i18nextLng");
  } else {
    if (localStorage.getItem("monoLanguage") === "true") {
      selected = "HE";
    } else {
      selected = "EN";
    }
    // doubleLanguage
    // selected = "EN";
    // monolanguage
    // selected = "HE";
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (localStorage.getItem("monoLanguage") === "true") {
      changeLanguageHandler("HE");
    } else {
      null;
    }
    // changeLanguageHandler("HE");
  }, [location]);

  const changeLanguageHandler = (e) => {
    const languageValue = e;
    i18n.changeLanguage(e);
    localStorage.setItem("lang", languageValue);
  };

  document.body.dir = languageMap[selected].dir
    ? languageMap[selected].dir
    : // doubleLanguage
    localStorage.getItem("monoLanguage") === "true"
    ? "rtl"
    : "ltr";

  // monoLanguage
  let index;

  let formclass = document.getElementsByClassName("form-control");
  for (index = 0; index < formclass.length; ++index) {
    formclass[index].dir = languageMap[selected].dir;
    if (formclass[index].type == "text") {
      document.getElementById(formclass[index].id).style.direction =
        languageMap[selected].dir;
      document.getElementById(formclass[index].id).style.textAlign =
        languageMap[selected].dir;
    }
  }

  const logOut = () => {
    localStorage.removeItem("utm");
    localStorage.removeItem("collective");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    location.reload();
  };
  // doubleLanguage
  const language = (e) => {
    if (localStorage.getItem("monoLanguage") === "true") {
      null;
    } else {
      if (e.target.innerText == "עִברִית") {
        changeLanguageHandler("HE");
      } else {
        changeLanguageHandler("EN");
        // }
      }
    }
  };

  // this function sets the timer that logs out the admin
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logOut();
      window.location.pathname = "/vitaju";
    }, parseInt(siteLogoutTime));
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  useEffect(() => {
    function checkUserData() {
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("role") === "admin"
      ) {
        Object.values(events).forEach((item) => {
          window.addEventListener(item, () => {
            resetTimer();
            handleLogoutTimer();
          });
        });
      }
    }
    window.addEventListener("storage", checkUserData);
    checkUserData();
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);

  return props.show ? (
    <div className={"mainDivHeb"}>
      <div className={"headerArrowHeb"}>
        <img src={top_left_bg}></img>
      </div>
      <Navbar expand="lg" className="nav_div">
        <Container fluid>
          <Navbar.Brand href="/" className="logo-link">
            <img src={logo} alt="" className="Blue_logo" />
            <img src={logowhite} alt="" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarCollapse"
            className="navbar-collapse collapse"
          >
            <Form className={"mainMenuHeb"}>
              <Nav
                className="me-auto my-2 my-lg-0"
                data-target="#navbarCollapse"
                data-toggle="collapse"
                aria-controls="navbarScroll"
              >
                {localStorage.getItem("token") ? (
                  <>
                    {localStorage.getItem("role") === "admin" ? (
                      <>
                        <Link to="/meetingrequest">{t("Create Meeting")}</Link>
                        <Link to="/createuser">{t("Create User")}</Link>
                        <Link to="/AdminDashboard">{t("View users")}</Link>
                        <Link to="/ViewMeeting">{t("View meetings")}</Link>
                        <Link
                          to=""
                          className="nav-link"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          {localStorage.getItem("userName")}
                        </Link>
                        <a href="/login" onClick={logOut}>
                          {t("Log Out")}
                        </a>
                        {/* doubleLanguage start*/}

                        {localStorage.getItem("monoLanguage") ===
                        "true" ? null : (
                          <>
                            <div className="linkdropdown">
                              <Nav.Link onClick={(e) => language(e)}>
                                עִברִית
                              </Nav.Link>
                              <Nav.Link onClick={(e) => language(e)}>
                                English
                              </Nav.Link>
                            </div>
                            <div
                              className="mb-2 languagedropdown"
                              style={{ margin: "3px auto" }}
                            >
                              <DropdownButton
                                className="buttonDrop"
                                key={"down"}
                                id={`dropdown-button-drop-down`}
                                drop={"down"}
                                variant="primary"
                                onSelect={changeLanguageHandler}
                                title={selected}
                              >
                                <Dropdown.Item
                                  // className="dropdownItem"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#18457d",
                                  }}
                                  eventKey="HE"
                                >
                                  עִברִית
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{
                                    color: "white",
                                    backgroundColor: "#18457d",
                                  }}
                                  // className="dropdownItem"
                                  eventKey="EN"
                                >
                                  English
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </>
                        )}
                        {/* doubleLanguage end */}
                      </>
                    ) : (
                      <>
                        {localStorage.getItem("role") === "client" ? (
                          <Link to="/meetingrequest">
                            {t("Create Meeting")}
                          </Link>
                        ) : null}
                        <Link to="/">{t("Dashboard")}</Link>
                        <Link to="/Profile">{t("Profile Setting")}</Link>
                        <Link to="">{localStorage.getItem("userName")}</Link>
                        <a href="/login" onClick={logOut}>
                          {t("Log Out")}
                        </a>
                        {/* doubleLanguage start*/}
                        {localStorage.getItem("monoLanguage") ===
                        "true" ? null : (
                          <>
                            <div className="linkdropdown">
                              <Nav.Link onClick={(e) => language(e)}>
                                עִברִית
                              </Nav.Link>
                              <Nav.Link onClick={(e) => language(e)}>
                                English
                              </Nav.Link>
                            </div>
                            <div
                              className="mb-2 languagedropdown"
                              style={{ margin: "3px auto" }}
                            >
                              <DropdownButton
                                className="buttonDrop"
                                key={"down"}
                                id={`dropdown-button-drop-down`}
                                drop={"down"}
                                variant="primary"
                                onSelect={changeLanguageHandler}
                                title={selected}
                              >
                                <Dropdown.Item
                                  // className="dropdownItem"
                                  style={{
                                    backgroundColor: "#18457d",
                                    color: "white",
                                  }}
                                  eventKey="HE"
                                >
                                  עִברִית
                                </Dropdown.Item>
                                <Dropdown.Item
                                  // className="dropdownItem"
                                  style={{
                                    color: "white",
                                    backgroundColor: "#18457d",
                                  }}
                                  eventKey="EN"
                                >
                                  English
                                </Dropdown.Item>
                              </DropdownButton>
                            </div>
                          </>
                        )}
                        {/* doubleLanguage end*/}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link to="/meetingrequest">{t("Create Meeting")}</Link>
                    <Link to="/">{t("Login")}</Link>
                    {/* doubleLanguage start*/}
                    {localStorage.getItem("monoLanguage") === "true" ? null : (
                      <>
                        <div className="linkdropdown">
                          <Nav.Link onClick={(e) => language(e)}>
                            עִברִית
                          </Nav.Link>
                          <Nav.Link onClick={(e) => language(e)}>
                            English
                          </Nav.Link>
                        </div>
                        <div
                          className="mb-2 languagedropdown"
                          style={{ margin: "3px auto" }}
                        >
                          <DropdownButton
                            className="buttonDrop"
                            key={"down"}
                            id={`dropdown-button-drop-down`}
                            drop={"down"}
                            variant="primary"
                            onSelect={changeLanguageHandler}
                            title={selected}
                          >
                            <Dropdown.Item
                              // className="dropdownItem"
                              style={{
                                color: "white",
                                backgroundColor: "#18457d",
                              }}
                              eventKey="HE"
                            >
                              עִברִית
                            </Dropdown.Item>
                            <Dropdown.Item
                              // className="dropdownItem"
                              style={{
                                color: "white",
                                backgroundColor: "#18457d",
                              }}
                              eventKey="EN"
                            >
                              English
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </>
                    )}
                    {/* doubleLanguage end*/}
                  </>
                )}

                <Nav.Link></Nav.Link>
              </Nav>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  ) : null;
};
export default MainHeader;

import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../My Queues/queues.css";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import { Network } from "../../config/axios";
import { doctorSummary } from "../../config/service";
import "react-quill/dist/quill.snow.css";
import GeneratePdf from "../../My Queues/GeneratePdf";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// import crossImage from "../../assets/images/cross/crs.png";
let typing = false;

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#000",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 20px",
    lineHeight: "16px",
    cursor: "pointer",
    borderRadius: "0px",
    ":hover": {
      background: "#1E0F3C",
      borderRadius: "5px",
      color: "#E9E7EC",
    },
  }),
  control: () => ({
    background: "transparent",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    color: "#000",
    display: "flex",
    alignItem: "center",
    height: "43px",
    margin: "0px",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    ":hover": {
      background: "transparent",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "500",
    fontSize: "18px",
    color: "#000",
  }),
  input: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "500",
    fontSize: "16px !important",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 20px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "20px",
    color: "#858585c7",
  }),
};

const InnerSummaryComponent = (props) => {
  const { t } = useTranslation();
  const [key, setKey] = useState("Summary");
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [generateUpdateModal, setGenerateupdateModal] = useState(false);
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const [summary, setSummary] = useState({});
  const [isNewValue, setisNewValue] = useState(true);
  const [errors, setErrors] = useState({});
  const [additionField, setAdditionField] = useState({
    name: "",
    position: "",
    data: "",
  });

  const initialFieldArray = [
    { name: "referral_reason", label: t("Reason for referral") },
    { name: "background_details", label: t("Background Details") },
    { name: "diagnsis", label: t("Diagonosis") },
    { name: "treatment_program", label: t("Treatment Program") },
  ];
  const [summaryFieldArray, setSummaryFieldArray] = useState(initialFieldArray);

  const [hover, setHover] = useState(false);
  const [noCallUpdateSummary, setNoCallUpdateSummary] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  const handleClose4 = () => setGenerateupdateModal(false);
  const [medicineList, setMedicineList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedSec, setSelectedSec] = useState(-1);
  const [medicineValue, setMedicineValue] = useState("");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [medicMetaDetails, setMedicMetaDetails] = useState({});
  const [cleared, setCleared] = React.useState(false);
  const [showInputField, setShowInputField] = useState(false);
  const [combineAddress, setCombineAddress] = useState("");

  const genderArr = [
    { value: "Male", label: t("Male") },
    { value: "Female", label: t("Female") },
  ];
  const continuingCare = [
    "הטיפול הסתיים",
    "הופנה לביצוע בדיקות",
    "לא מתאים לאונליין - הופנה לטיפול פרונטלי",
    "הזמנה להמשך מעקב פרונטלי",
    "הזמנה להמשך מעקב אונליין",
  ];
  const quantityArray = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  const timesArray = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const handleCheckDoctorAddress = () => {
    const fullAddress = props?.doctorData?.clientAddress;
    if (
      fullAddress?.city &&
      fullAddress?.house &&
      fullAddress?.pincode &&
      fullAddress?.street
    ) {
      setShowInputField(true);
      setCombineAddress(
        props?.doctorData.phone +
          " " +
          fullAddress?.street +
          " " +
          fullAddress?.house +
          " " +
          fullAddress?.city +
          " " +
          fullAddress?.pincode
      );
    } else {
      setShowInputField(false);
    }
  };

  const handleEditInputField = () => {
    setShowInputField(false);
  };

  useEffect(() => {
    handleCheckDoctorAddress();
  }, []);

  const handlePeriodArray = () => {
    let periodArray = [];
    for (let i = 1; i <= 180; i++) {
      periodArray.push({ value: i, label: i });
    }
    return periodArray;
  };
  const allfrequencyArray = [
    { value: "day", label: t("day") },
    { value: "week", label: t("week") },
    // { value: "month", label: t("month") },
  ];
  const frequencyArray =
    props.checkIDF && props.checkIDF === "640ed7103f08dcee2d1f1719"
      ? allfrequencyArray.filter(
          (el) => !["week", "month"]?.includes(el?.value)
        )
      : allfrequencyArray;
  const alldurationArray = [
    { value: "day", label: t("days") },
    { value: "week", label: t("weeks") },
    // { value: "month", label: t("months") },
  ];

  const durationArray =
    props.checkIDF && props.checkIDF === "640ed7103f08dcee2d1f1719"
      ? alldurationArray.filter((el) => !["week", "month"]?.includes(el?.value))
      : alldurationArray;

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectedSec(-1);
          setSearchKey("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleMedicine = (value, name, index) => {
    setMedicineValue(value);
    enableMedicForm(value, index);

    const copy = [...prescriptionData];
    copy[index]["name"] = value?.Name;
    copy[index]["medicineId"] = value?._id;
    setPrescriptionData(copy);
    if (name === "inputVal" && medicineValue === "") {
      setSelectedSec(index);
    }
    if (name === "listVal" || value === "") {
      setSelectedSec(-1);
    }
  };

  const enableMedicForm = (value, index) => {
    const userData = props?.userData;
    const doctorData = props?.doctorData;
    const userAddress = userData?.clientAddress;
    const doctorAddress = doctorData?.clientAddress;

    const isSamObj = medicMetaDetails;
    isSamObj[index] = value?.IsSam;
    setMedicMetaDetails(isSamObj);
    if (value?.IsSam) {
      let prescribeData = [...prescriptionData];
      prescribeData[index]["isSam"] = value?.IsSam;
      prescribeData[index]["patient_street"] =
        prescribeData[index]["patient_street"] || userAddress?.street || "";
      prescribeData[index]["patient_houseno"] =
        prescribeData[index]["patient_houseno"] || userAddress?.house || "";
      prescribeData[index]["patient_city"] =
        prescribeData[index]["patient_city"] || userAddress?.city || "";
      prescribeData[index]["patient_pincode"] =
        prescribeData[index]["patient_pincode"] || userAddress?.pincode || "";
      prescribeData[index]["patient_dob"] = prescribeData[index]["patient_dob"]
        ? moment(prescribeData[index]["patient_dob"])
        : moment(new Date(userData?.clientDOB));
      prescribeData[index]["patient_gender"] =
        prescribeData[index]["patient_gender"] || userData?.clientGender || "";

      prescribeData[index]["doctor_phone"] =
        prescribeData[index]["doctor_phone"] || doctorData?.phone || "";
      prescribeData[index]["doctor_street"] =
        prescribeData[index]["doctor_street"] || doctorAddress?.street || "";
      prescribeData[index]["doctor_city"] =
        prescribeData[index]["doctor_city"] || doctorAddress?.city || "";
      prescribeData[index]["doctor_houseno"] =
        prescribeData[index]["doctor_houseno"] || doctorAddress?.house || "";
      prescribeData[index]["doctor_pincode"] =
        prescribeData[index]["doctor_pincode"] || doctorAddress?.pincode || "";

      setPrescriptionData(prescribeData);
    } else {
      let prescribeData = [...prescriptionData];
      delete prescribeData[index]["patient_street"];
      delete prescribeData[index]["patient_houseno"];
      delete prescribeData[index]["patient_city"];
      delete prescribeData[index]["patient_pincode"];
      delete prescribeData[index]["patient_dob"];
      delete prescribeData[index]["patient_gender"];
      delete prescribeData[index]["doctor_phone"];
      delete prescribeData[index]["doctor_street"];
      delete prescribeData[index]["doctor_city"];
      delete prescribeData[index]["doctor_houseno"];
      delete prescribeData[index]["doctor_pincode"];
      setPrescriptionData(prescribeData);
    }
  };

  useEffect(() => {
    if (
      props?.updateSummaryResponce &&
      Object.keys(props?.updateSummaryResponce).length
    ) {
      let summaryObj = {
        background_details: props.updateSummaryResponce.background_details,
        diagnsis: props.updateSummaryResponce.diagnsis,
        referral_reason: props.updateSummaryResponce.referral_reason,
        treatment_program: props.updateSummaryResponce.treatment_program,
        continuingCare: props.updateSummaryResponce.continuingCare,
      };
      setSummary(summaryObj);
      if (
        props?.updateSummaryResponce.prescriptionFields &&
        props?.updateSummaryResponce.prescriptionFields[0]
      ) {
        const additionalRes =
          props?.updateSummaryResponce.prescriptionFields[0];
        handleProfileFieldData(additionalRes, summaryObj);
        setAdditionField({
          name: additionalRes.name,
          position: additionalRes.position,
          data: additionalRes.data,
        });
      }
    } else if (
      !props?.updateSummaryResponce ||
      Object.keys(props?.updateSummaryResponce)?.length === 0
    ) {
      setSummary({});
    }
  }, [props.updateSummaryResponce, props.meetingId]);

  useEffect(() => {
    let isValid = false;
    for (let key of Object.keys(summary)) {
      if (summary[key]) {
        isValid = true;
        break;
      }
    }
    setisNewValue(isValid);
  }, [summary]);
  useEffect(() => {
    allMedicine(searchKey || "a");
  }, [props.meetingId, searchKey]);

  useEffect(() => {
    if (props.meetingId) {
      getPrescription();
    }
  }, [props.meetingId]);

  const getPrescription = async () => {
    try {
      const response = await Network().get(
        `/meeting/meetingPrescriptionByMeetingId/${props.meetingId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      );

      if ([200, 201, 203].includes(response.data.code)) {
        setPrescriptionData(
          response.data.meetinPrescription.map((d, index) => {
            const isSamObj = medicMetaDetails;
            isSamObj[index] = d?.isSam;
            setMedicMetaDetails(isSamObj);

            return {
              medicineId: d.medicationId,
              name: d.name,
              quantity: d.quantity,
              frequency: d.frequency,
              frequencyUnit: d.frequencyUnit,
              duration: d.duration,
              durationUnit: d.durationUnit,
              chronic: d.chronic,
              usedOnRegularBasis: d.usedOnRegularBasis,
              remarks: d.remarks,
              patient_street: d.patient_street,
              patient_houseno: d.patient_houseno,
              patient_city: d.patient_city,
              patient_pincode: d.patient_pincode,
              patient_dob: d.patient_dob
                ? moment(d.patient_dob)
                : moment(new Date()),
              patient_gender: d.patient_gender,
              doctor_phone: d.doctor_phone,
              doctor_street: d.doctor_street,
              doctor_city: d.doctor_city,
              doctor_houseno: d.doctor_houseno,
              doctor_pincode: d.doctor_pincode,
              isSam: d.isSam,
              dosage: d.dosage,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleinputchange = (e, index) => {
    // if (e.target) {
    //   const { name, value } = e.target;
    //   const list = prescriptionData;
    //   if (name == "chronic" || name == "usedOnRegularBasis") {
    //     const checked = e.target.checked;
    //     list[index][name] = checked;
    //   } else {
    //     list[index][name] = value;
    //   }

    //   setPrescriptionData(list);
    // } else {
    //   const list = prescriptionData;
    //   list[index]["medicineId"] = e._id;
    //   list[index]["name"] = e.Name;
    //   setPrescriptionData(list);
    // }

    setPrescriptionData((prevState) => {
      const updatedData = [...prevState];
      if (e.target) {
        const { name, value } = e.target;
        if (name === "chronic" || name === "usedOnRegularBasis") {
          const checked = e.target.checked;
          updatedData[index] = {
            ...updatedData[index],
            [name]: checked,
          };
        } else if (name === "doctor_phone" && /^[0-9+ -]*$/.test(value)) {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
          };
        } else if (name !== "doctor_phone" && name !== "dosage") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
          };
        } else if (name === "dosage") {
          const finalValue = Number(value);
          updatedData[index] = {
            ...updatedData[index],
            [name]: finalValue,
          };
        }
      } else {
        updatedData[index] = {
          ...updatedData[index],
          medicineId: e._id,
          name: e.Name,
        };
      }

      return updatedData;
    });
  };
  const handleremove = (data) => {
    const updatedPrescriptionData = prescriptionData.filter((d) => d !== data);
    setPrescriptionData(updatedPrescriptionData);
    const updatedMedicMetaDetails = {};

    updatedPrescriptionData.forEach((item, index) => {
      updatedMedicMetaDetails[index] = item?.isSam;
    });
    setMedicMetaDetails(updatedMedicMetaDetails);
    setErrors({});
  };
  const handleaddclick = () => {
    const blankPrescriptioField = {
      medicineId: "",
      name: "",
      quantity: "",
      frequency: "",
      frequencyUnit: "",
      duration: "",
      durationUnit: "",
      remarks: "",
      chronic: false,
      usedOnRegularBasis: false,
    };
    if (props.hasMeetingType) {
      blankPrescriptioField.dosage = "";
    }
    setPrescriptionData((prevData) => [...prevData, blankPrescriptioField]);
    setMedicineValue({});
    setSearchKey("");
  };

  const allMedicine = async (data) => {
    if (data) {
      try {
        const response = await Network().get("/doctor/medications/" + data, {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        });

        setMedicineList(response.data.medication);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validation = () => {
    let isValid = true;
    const errorObj = {};
    let toastShown = {};
    prescriptionData.forEach((d, index) => {
      if (!errorObj[index]) {
        errorObj[index] = [];
      }

      const getToastId = (field) => `${index}-${field}`;

      const showToast = (message, field) => {
        const id = getToastId(field);
        if (!toastShown[id]) {
          toast.error(t(message), { toastId: id });
          toastShown[id] = true;
        }
      };

      if (!d.name) {
        showToast("Medicine name is a mandatory field");
        errorObj[index].push("name");
        isValid = false;
      }
      if (!d.quantity) {
        showToast("Quantity is a mandatory field");
        errorObj[index].push("quantity");
        isValid = false;
      }

      if (medicMetaDetails[index] && props.hasMeetingType) {
        if (!d.patient_street) {
          showToast("Patient street address is a mandatory field");
          errorObj[index].push("patient_street");
          isValid = false;
        }
        if (!d.patient_houseno) {
          showToast("Patient house no is a mandatory field");
          errorObj[index].push("patient_houseno");
          isValid = false;
        }
        if (!d.patient_city) {
          showToast("Patient city is a mandatory field");
          errorObj[index].push("patient_city");
          isValid = false;
        }
        if (!d.patient_pincode) {
          showToast("Patient pincode is a mandatory field");
          errorObj[index].push("patient_pincode");
          isValid = false;
        }
        if (!d.patient_dob) {
          showToast("Patient DOB is a mandatory field");
          errorObj[index].push("patient_dob");
          isValid = false;
        }
        if (!d.patient_gender) {
          showToast("Patient Gender is a mandatory field");
          errorObj[index].push("patient_gender");
          isValid = false;
        }
        if (!d.doctor_phone) {
          showToast("Clinic phone no is a mandatory field");
          errorObj[index].push("doctor_phone");
          isValid = false;
        }
        if (!d.doctor_street) {
          showToast("Doctor street is a mandatory field");
          errorObj[index].push("doctor_street");
          isValid = false;
        }
        if (!d.doctor_houseno) {
          showToast("Doctor house no is a mandatory field");
          errorObj[index].push("doctor_houseno");
          isValid = false;
        }
        if (!d.doctor_city) {
          showToast("Doctor city is a mandatory field");
          errorObj[index].push("doctor_city");
          isValid = false;
        }
        if (!d.doctor_pincode) {
          showToast("Doctor pincode is a mandatory field");
          errorObj[index].push("doctor_pincode");
          isValid = false;
        }
      }

      if (!d.frequency) {
        showToast("Times is a mandatory field");
        errorObj[index].push("frequency");
        isValid = false;
      }
      if (!d.frequencyUnit) {
        showToast("Frequency is a mandatory field");
        errorObj[index].push("frequencyUnit");
        isValid = false;
      }
      if (!d.duration) {
        showToast("Period is a mandatory field");
        errorObj[index].push("duration");
        isValid = false;
      }
      if (!d.durationUnit) {
        showToast("Duration is a mandatory field");
        errorObj[index].push("durationUnit");
        isValid = false;
      }
      if (!d.dosage && props.hasMeetingType) {
        showToast("Dosage is a mandatory field");
        errorObj[index].push("dosage");
        isValid = false;
      }
    });

    return { isValid, errorObj };
  };

  const handleSummarychange = (e, key) => {
    const { value } = e.target;
    setSummary({ ...summary, [key]: value });
    typing = true;
  };

  const handleProfileFieldData = (data, summaryObj) => {
    const newField = {
      name: data.name,
      label: data.name,
    };
    const newPosition = parseInt(data.position);
    const insertIndex = newPosition - 1;
    let copySummary = JSON.parse(JSON.stringify(initialFieldArray));

    setSummaryFieldArray([
      ...copySummary.slice(0, insertIndex),
      newField,
      ...copySummary.slice(insertIndex),
    ]);
    setSummary({ ...summaryObj, [data.name]: data.data });
  };

  const updateSummary = async () => {
    if (typing === false) {
      return;
    }
    const data = {
      meetingId: props.meetingId,
      ...summary,
      prescription: prescriptionData,
    };
    let newFieldValue;
    if (additionField && additionField.name) {
      const summaryKeys = Object.keys(summary);
      if (summaryKeys.includes(additionField.name)) {
        newFieldValue = summary[additionField.name];
      }
      data.prescriptionFields = {
        name: additionField.name,
        position: additionField.position,
        data: newFieldValue ? newFieldValue : "",
      };
    }

    const response = await doctorSummary(data);
    if (props.meetingValue === "past") {
      props.setMeetingValue("past");
    }
    toast.success(t(response.data.msg));
  };

  useEffect(() => {
    setNoCallUpdateSummary(true);
    const interval = setInterval(() => {
      if (typing) {
        updateSummary();
        typing = false;
        setNoCallUpdateSummary(false);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [summary]);

  const uploadSummary = async (e, isSign = false) => {
    e.preventDefault();
    let data = {
      meetingId: props.meetingId,
      prescription: prescriptionData,
    };
    if (summary) {
      data = { ...data, ...summary };
    }

    let newFieldValue;
    if (additionField && additionField.name) {
      const summaryKeys = Object.keys(summary);
      if (summaryKeys.includes(additionField.name)) {
        newFieldValue = summary[additionField.name];
      }
      data.prescriptionFields = {
        name: additionField.name,
        position: additionField.position,
        data: newFieldValue ? newFieldValue : "",
      };
    }
    const { isValid, errorObj } = validation();
    if (!isValid) {
      setErrors(errorObj);
      return;
    }
    const response = await doctorSummary(data);
    if (
      props.updateSummaryResponce &&
      Object.values(props.updateSummaryResponce).length
    ) {
      props.setUpdateSummaryResponse({
        ...props.updateSummaryResponce,
        ...data,
      });
    }
    if (!isSign) {
      toast.success(t(response.data.msg));
      toast.warning(
        t("Please note, that the summary draft wasn't sent to the client yet")
      );
      props.handleClose();
    }
  };

  const signandsend = async (e) => {
    uploadSummary(e, true);
    if (
      props.checkIDF &&
      props.checkIDF === "640ed7103f08dcee2d1f1719" &&
      !summary?.continuingCare
    ) {
      toast.error(t("Continue treatment must be selected"));
      return;
    }
    const { isValid } = validation();
    if (isValid === true) {
      setGenerateupdateModal(true);
    }
  };
  const updateWithClose = () => {
    if (typing) {
      updateSummary();
      typing = false;
      setNoCallUpdateSummary(false);
    }
    setSummary({});
    setMedicineValue("");
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      let element = document.querySelector(".red-border");
      if (element) {
        element?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      setTimeout(() => {
        setErrors({});
      }, 20000);
    }
  }, [Object.keys(errors).length > 0]);

  useEffect(() => {
    if (props.meetingId) {
      getPrescription();
    }
  }, [props.meetingId]);

  const searchMedicine = (event) => {
    if (event.code.includes("Key")) {
      setSearchKey((old) => old + event.code.replace("Key", "").toUpperCase());
    } else if (event.code.includes("Backspace")) {
      setSearchKey((old) => {
        return old.substring(0, old.length - 1);
      });
    } else {
      setSearchKey(event.target.value);
    }
  };

  const handleListModal = (index) => {
    setSelectedSec(index);
  };
  const CustomToolTip = ({ id, children, title }) => (
    <OverlayTrigger
      // delay={{ hide: 40000 }}
      overlay={
        <Tooltip id={id} className="prescription-tooltip">
          {title}
        </Tooltip>
      }
    >
      <span className="tooltip-circle">{children}</span>
    </OverlayTrigger>
  );

  const onMedicineMetaDOBChange = (value, index, fieldName) => {
    if (value) {
      let prescribeData = prescriptionData;
      prescribeData[index][fieldName] = value;
      setPrescriptionData(prescribeData);
    }
  };

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [cleared]);

  return (
    <div className="inner-summary">
      <GeneratePdf
        generateUpdateModal={generateUpdateModal}
        handleClose4={handleClose4}
        meetingId={props.meetingId}
        handleClose={props.handleClose}
      />

      {key == "Summary" ? (
        <>
          <div className="toggleDiv">
            <div className="tab-grid">
              <span
                onClick={() => {
                  setKey("Summary");
                  updateSummary();
                }}
                className={key == "Summary" ? "spanActive" : "toggle "}
              >
                {t("Summary")}
              </span>
              <span
                onClick={() => {
                  setKey("Prescription");
                  if (!noCallUpdateSummary) {
                    updateSummary();
                  }
                }}
                className={key == "Prescription" ? "spanActive" : "toggle "}
              >
                {t("Prescription")}
              </span>
            </div>
            <div className="user-name">
              <h5>
                <span>{t("Patient Name")} </span>
                {props.userName}
              </h5>
            </div>
          </div>
          <Form className="summarytext">
            {summaryFieldArray.map((field, i) => {
              return (
                <Form.Group key={i}>
                  <h3>{field?.label}</h3>
                  <textarea
                    type="text"
                    rows={3}
                    onChange={(e) => handleSummarychange(e, `${field?.name}`)}
                    value={summary[field?.name]}
                    placeholder={field?.label}
                    className="form-control summary_text"
                  />
                </Form.Group>
              );
            })}

            {/* Old code start */}
            {/* <Form.Group>
              <h3> {t("Reason for referral")}</h3>
              <textarea
                type="text"
                rows={3}
                onChange={(e) => handleSummarychange(e, "referral_reason")}
                value={summary?.referral_reason}
                placeholder={t("Reason for referral")}
                className="form-control summary_text"
              />
            </Form.Group>
            <Form.Group>
              <h3> {t("Background Details")}</h3>
              <textarea
                type="text"
                rows={3}
                onChange={(e) => handleSummarychange(e, "background_details")}
                value={summary?.background_details}
                placeholder={t("Background Details")}
                className="form-control summary_text"
              />
            </Form.Group>
            <Form.Group>
              <h3> {t("Diagonosis")}</h3>
              <textarea
                type="text"
                rows={3}
                onChange={(e) => handleSummarychange(e, "diagnsis")}
                value={summary?.diagnsis}
                placeholder={t("Diagonosis")}
                className="form-control summary_text"
              />
            </Form.Group>
            <Form.Group>
              <h3> {t("Treatment Program")}</h3>
              <textarea
                type="text"
                rows={3}
                onChange={(e) => handleSummarychange(e, "treatment_program")}
                value={summary?.treatment_program}
                placeholder={t("Treatment Program")}
                className="form-control summary_text"
              />
            </Form.Group> */}
            {/* Old code End */}

            {props.checkIDF && props.checkIDF === "640ed7103f08dcee2d1f1719" ? (
              <Form.Group className="frequency">
                <h3> {t("Continuing Care")}</h3>
                <select
                  className="form-control"
                  name="continuingCare"
                  onChange={(e) => handleSummarychange(e, "continuingCare")}
                  autoComplete="off"
                >
                  <option value="">{t("Select")}</option>

                  {continuingCare.map((d, i) => {
                    return (
                      <option
                        key={i + 1}
                        value={d}
                        selected={summary?.continuingCare?.trim() === d.trim()}
                      >
                        {d}
                      </option>
                    );
                  })}
                </select>
              </Form.Group>
            ) : null}
          </Form>
        </>
      ) : (
        <>
          <div className="toggleDiv">
            <div className="tab-grid">
              <span
                onClick={() => {
                  setKey("Summary");
                  updateSummary();
                }}
                className={key == "Summary" ? "spanActive" : "toggle "}
              >
                {t("Summary")}
              </span>
              <span
                onClick={() => {
                  setKey("Prescription");
                  updateSummary();
                }}
                className={key == "Prescription" ? "spanActive" : "toggle "}
              >
                {t("Prescription")}
              </span>
            </div>
            <div className="user-name">
              <h5>
                <span>{t("Patient Name")} </span>
                {props.userName}
              </h5>
            </div>
          </div>
          <Form className="form_sammary_press">
            {prescriptionData.length != 0 ? (
              prescriptionData.map((data, i) => {
                return (
                  <div className="form_sammary_field" key={i}>
                    <h2> {t("Update prescription")}</h2>
                    <div>
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                        className="medicine-row"
                      >
                        <Form.Group className="frequency medicine-select">
                          <Form.Label> {t("Medicine Name")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="medcineList"
                            placeholder="Select"
                            onChange={(e) =>
                              handleMedicine(e.target.value, "inputVal", i)
                            }
                            onKeyDown={searchMedicine}
                            onFocus={() => handleListModal(i)}
                            value={data?.name || medicineValue?.Name}
                            className={`${
                              errors[i]?.indexOf("name") > -1
                                ? "red-border"
                                : ""
                            }`}
                            autoComplete="off"
                          />
                          {i === selectedSec &&
                          medicineList &&
                          medicineList.length ? (
                            <ul className="auto-complete" ref={wrapperRef}>
                              {medicineList.map((el) => (
                                <li
                                  className="auto-complete-list"
                                  value={el.name}
                                  key={el._id}
                                  onClick={() =>
                                    handleMedicine(el, "listVal", i)
                                  }
                                  name="subcategory"
                                >
                                  {el.Name}
                                </li>
                              ))}
                            </ul>
                          ) : medicineList && !medicineList.length ? (
                            <ul className="auto-complete" ref={wrapperRef}>
                              <li>No Results Found</li>
                            </ul>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="frequency">
                          <Form.Label>
                            {" "}
                            {t("Quantity")}{" "}
                            <CustomToolTip
                              title={t(
                                "Quantity: the number of packs of the medicine"
                              )}
                              id="quantity"
                            >
                              {" "}
                              ?{" "}
                            </CustomToolTip>{" "}
                          </Form.Label>
                          <Select
                            className={` ${
                              errors[i]?.indexOf("quantity") > -1
                                ? "red-border"
                                : ""
                            }`}
                            classNamePrefix="prescription"
                            defaultValue={
                              data?.quantity
                                ? {
                                    value: data?.quantity,
                                    label: data?.quantity,
                                  }
                                : ""
                            }
                            isRtl={lang === "HE"}
                            noOptionsMessage={() =>
                              t("Type a number between 1-10")
                            }
                            styles={customStyles}
                            isSearchable={true}
                            options={quantityArray}
                            name="quantity"
                            placeholder="Select"
                            onChange={(e) =>
                              handleinputchange(
                                {
                                  target: {
                                    name: "quantity",
                                    value: e.value,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </Form.Group>
                      </div>

                      <h2> {t("Instruction of use")}</h2>
                      {props.hasMeetingType && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Form.Group className="frequency new-field">
                            <Form.Label>
                              {" "}
                              {t("Dosage")}{" "}
                              <CustomToolTip
                                title={t(
                                  "Dosage: The number of units to be taken for each occasion of taking the medicine"
                                )}
                                id="dosage"
                              >
                                ?{" "}
                              </CustomToolTip>{" "}
                            </Form.Label>

                            <Form.Control
                              className={` ${
                                errors[i]?.indexOf("dosage") > -1
                                  ? "red-border"
                                  : ""
                              }`}
                              name="dosage"
                              min="0"
                              onKeyDown={(e) =>
                                e.key === "-" && e.preventDefault()
                              }
                              type="number"
                              onChange={(e) => handleinputchange(e, i)}
                              id="dosage"
                              placeholder={t("Dosage")}
                              value={data?.dosage}
                              style={{
                                textAlign: lang === "HE" ? "right" : "left",
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="frequency new-field" />
                        </div>
                      )}

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Group className="frequency">
                          <Form.Label>
                            {" "}
                            {t("Times")}{" "}
                            <CustomToolTip
                              title={t(
                                props.hasMeetingType
                                  ? "The number of doses to be taken per unit of time according to the frequency in the next field"
                                  : "Number: the number of units of the drug that should be taken each time the drug is taken"
                              )}
                              id="times"
                            >
                              ?{" "}
                            </CustomToolTip>
                          </Form.Label>
                          <Select
                            className={` ${
                              errors[i]?.indexOf("frequency") > -1
                                ? "red-border"
                                : ""
                            }`}
                            classNamePrefix="prescription"
                            defaultValue={
                              data?.frequency
                                ? {
                                    value: data.frequency,
                                    label: data.frequency,
                                  }
                                : ""
                            }
                            isRtl={lang === "HE"}
                            placeholder="Select"
                            noOptionsMessage={() =>
                              t("Type a number between 1-10")
                            }
                            styles={customStyles}
                            isSearchable={true}
                            options={timesArray}
                            name="frequency"
                            onChange={(e) =>
                              handleinputchange(
                                {
                                  target: {
                                    name: "frequency",
                                    value: e.value,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group className="frequency">
                          <Form.Label>
                            {" "}
                            {t("Frequency")}{" "}
                            <CustomToolTip
                              title={t(
                                props.hasMeetingType
                                  ? "The time unit as defined in relation to the number in the previous field"
                                  : "Frequency: frequency of taking the drug, per day/week/month"
                              )}
                              id="times"
                            >
                              ?{" "}
                            </CustomToolTip>
                          </Form.Label>
                          <Select
                            className={` ${
                              errors[i]?.indexOf("frequencyUnit") > -1
                                ? "red-border"
                                : ""
                            }`}
                            classNamePrefix="prescription"
                            defaultValue={
                              data.frequencyUnit
                                ? {
                                    value: t(data.frequencyUnit),
                                    label: t(data.frequencyUnit),
                                  }
                                : ""
                            }
                            isRtl={lang === "HE"}
                            styles={customStyles}
                            isSearchable={true}
                            options={frequencyArray}
                            placeholder="Select"
                            name="frequencyUnit"
                            onChange={(e) =>
                              handleinputchange(
                                {
                                  target: {
                                    name: "frequencyUnit",
                                    value: e.value,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </Form.Group>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Group className="frequency">
                          <Form.Label>
                            {" "}
                            {t("Period")}{" "}
                            <CustomToolTip
                              title={t(
                                "For a period of: the number of days/weeks the patient has to repeat taking the medicine"
                              )}
                              id="times"
                            >
                              ?{" "}
                            </CustomToolTip>{" "}
                          </Form.Label>
                          <Select
                            className={` ${
                              errors[i]?.indexOf("duration") > -1
                                ? "red-border"
                                : ""
                            }`}
                            classNamePrefix="prescription"
                            // Find the element in periodArray corresponding to the value of data?.duration
                            // If data?.duration is 1, it corresponds to the element at index 0 in the array.
                            defaultValue={
                              data?.duration
                                ? handlePeriodArray()[
                                    Number(data?.duration) - 1
                                  ]
                                : null
                            }
                            isRtl={lang === "HE"}
                            noOptionsMessage={() =>
                              t("Enter a number between 1-180")
                            }
                            styles={customStyles}
                            isSearchable={true}
                            options={handlePeriodArray()}
                            placeholder="Select"
                            name="duration"
                            onChange={(e) =>
                              handleinputchange(
                                {
                                  target: {
                                    name: "duration",
                                    value: e.value,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group className="frequency">
                          <Form.Label>
                            {" "}
                            {t("Duration for")}{" "}
                            <CustomToolTip
                              title={t(
                                "Duration: the unit of measure (day/week...) that refers to the previous field"
                              )}
                              id="times"
                            >
                              ?{" "}
                            </CustomToolTip>
                          </Form.Label>
                          <Select
                            className={` ${
                              errors[i]?.indexOf("durationUnit") > -1
                                ? "red-border"
                                : ""
                            }`}
                            classNamePrefix="prescription"
                            defaultValue={
                              data?.durationUnit
                                ? {
                                    value: t(data?.durationUnit),
                                    label: t(data?.durationUnit),
                                  }
                                : ""
                            }
                            isRtl={lang === "HE"}
                            noOptionsMessage={() => t("No option available")}
                            styles={customStyles}
                            isSearchable={true}
                            options={durationArray}
                            placeholder="Select"
                            name="durationUnit"
                            autoComplete={true}
                            onChange={(e) =>
                              handleinputchange(
                                {
                                  target: {
                                    name: "durationUnit",
                                    value: e.value,
                                  },
                                },
                                i
                              )
                            }
                          />
                        </Form.Group>
                      </div>

                      {/*==================== doctor and patient meta details for IsSam ======================*/}

                      {props.hasMeetingType && medicMetaDetails[i] && (
                        <>
                          <h2> {t("Patient Details")}</h2>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Group className="frequency new-field">
                              <Form.Label> {t("Street")} </Form.Label>
                              <Form.Control
                                className={` ${
                                  errors[i]?.indexOf("patient_street") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                name="patient_street"
                                type="text"
                                onChange={(e) => handleinputchange(e, i)}
                                id="patient_street"
                                placeholder={t("Street")}
                                defaultValue={data?.patient_street}
                              />
                            </Form.Group>

                            <Form.Group className="frequency new-field">
                              <Form.Label> {t("House No")} </Form.Label>
                              <Form.Control
                                className={` ${
                                  errors[i]?.indexOf("patient_houseno") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                name="patient_houseno"
                                type="text"
                                onChange={(e) => handleinputchange(e, i)}
                                id="patient_houseno"
                                placeholder={t("House No")}
                                defaultValue={data?.patient_houseno}
                              />
                            </Form.Group>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Group className="frequency new-field">
                              <Form.Label> {t("City")} </Form.Label>
                              <Form.Control
                                className={` ${
                                  errors[i]?.indexOf("patient_city") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                name="patient_city"
                                type="text"
                                onChange={(e) => handleinputchange(e, i)}
                                id="patient_city"
                                placeholder={t("City")}
                                defaultValue={data?.patient_city}
                              />
                            </Form.Group>

                            <Form.Group className="frequency new-field">
                              <Form.Label> {t("Postal Code")} </Form.Label>
                              <Form.Control
                                className={` ${
                                  errors[i]?.indexOf("patient_pincode") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                name="patient_pincode"
                                type="text"
                                onChange={(e) => handleinputchange(e, i)}
                                id="patient_pincode"
                                placeholder={t("Postal Code")}
                                defaultValue={data?.patient_pincode}
                              />
                            </Form.Group>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Group className="frequency new-field">
                              <Form.Label> {t("Date of Birth")} </Form.Label>

                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  slotProps={{
                                    field: {
                                      clearable: true,
                                      onClear: () => setCleared(true),
                                    },
                                  }}
                                  views={["year", "month", "day"]}
                                  onChange={(e) =>
                                    onMedicineMetaDOBChange(e, i, "patient_dob")
                                  }
                                  value={
                                    data?.patient_dob
                                      ? data?.patient_dob
                                      : props?.updateSummaryResponce?.clientDOB
                                  }
                                  disableFuture
                                  format="DD/MM/YYYY"
                                  inputFormat="DD/MM/YYYY"
                                  className="rx-dob"
                                  // openTo="year"
                                />
                              </LocalizationProvider>
                            </Form.Group>

                            <Form.Group className="frequency new-field">
                              <Form.Label> {t("Gender")} </Form.Label>

                              {/* <Form.Select
                                name="patient_gender"
                                onChange={(e) => handleinputchange(e, i)}
                                value={data?.patient_gender}
                              >
                                <option value="">{t("Select")}</option>
                                <option value={`Male`}>{t("Male")}</option>
                                <option value={`Female`}>{t("Female")}</option>
                              </Form.Select> */}

                              <Select
                                className={` ${
                                  errors[i]?.indexOf("patient_gender") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                classNamePrefix="prescription"
                                defaultValue={
                                  data?.patient_gender
                                    ? {
                                        value: data?.patient_gender
                                          ? data?.patient_gender
                                          : "",
                                        label: data?.patient_gender
                                          ? t(data?.patient_gender)
                                          : "",
                                      }
                                    : ""
                                }
                                isRtl={lang === "HE"}
                                noOptionsMessage={() =>
                                  t("Please select your gender Male/Female")
                                }
                                styles={customStyles}
                                isSearchable={true}
                                options={genderArr}
                                name="patient_gender"
                                placeholder="Select"
                                onChange={(e) =>
                                  handleinputchange(
                                    {
                                      target: {
                                        name: "patient_gender",
                                        value: e.value,
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </div>

                          <h2> {t("Doctor Details")}</h2>
                          {!showInputField ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Form.Group className="frequency new-field">
                                  <Form.Label>
                                    {" "}
                                    {t("Clinic phone number")}{" "}
                                  </Form.Label>
                                  <Form.Control
                                    name="doctor_phone"
                                    className={`phone_number ${
                                      errors[i]?.indexOf("doctor_phone") > -1
                                        ? "red-border"
                                        : ""
                                    }`}
                                    type="text"
                                    id="doctor_phone"
                                    placeholder={t("Clinic phone number")}
                                    pattern="[+0-9 -]+"
                                    onChange={(e) => handleinputchange(e, i)}
                                    value={data?.doctor_phone}
                                  />
                                </Form.Group>
                                <Form.Group className="frequency new-field">
                                  <Form.Label> {t("Street")} </Form.Label>
                                  <Form.Control
                                    className={` ${
                                      errors[i]?.indexOf("doctor_street") > -1
                                        ? "red-border"
                                        : ""
                                    }`}
                                    name="doctor_street"
                                    type="text"
                                    onChange={(e) => handleinputchange(e, i)}
                                    id="doctor_street"
                                    placeholder={t("Street")}
                                    defaultValue={data?.doctor_street}
                                  />
                                </Form.Group>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Form.Group className="frequency new-field">
                                  <Form.Label> {t("House No")} </Form.Label>
                                  <Form.Control
                                    className={` ${
                                      errors[i]?.indexOf("doctor_houseno") > -1
                                        ? "red-border"
                                        : ""
                                    }`}
                                    name="doctor_houseno"
                                    type="text"
                                    onChange={(e) => handleinputchange(e, i)}
                                    id="doctor_houseno"
                                    placeholder={t("House No")}
                                    defaultValue={data?.doctor_houseno}
                                  />
                                </Form.Group>
                                <Form.Group className="frequency new-field">
                                  <Form.Label> {t("City")} </Form.Label>
                                  <Form.Control
                                    className={` ${
                                      errors[i]?.indexOf("doctor_city") > -1
                                        ? "red-border"
                                        : ""
                                    }`}
                                    name="doctor_city"
                                    type="text"
                                    onChange={(e) => handleinputchange(e, i)}
                                    id="doctor_city"
                                    placeholder={t("City")}
                                    defaultValue={data?.doctor_city}
                                  />
                                </Form.Group>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Form.Group className="frequency new-field">
                                  <Form.Label> {t("Postal Code")} </Form.Label>
                                  <Form.Control
                                    className={` ${
                                      errors[i]?.indexOf("doctor_pincode") > -1
                                        ? "red-border"
                                        : ""
                                    }`}
                                    name="doctor_pincode"
                                    type="text"
                                    onChange={(e) => handleinputchange(e, i)}
                                    id="doctor_pincode"
                                    placeholder={t("Postal Code")}
                                    defaultValue={data?.doctor_pincode}
                                  />
                                </Form.Group>
                              </div>
                            </>
                          ) : (
                            <Form.Group
                              className="frequency "
                              style={{ width: "100%", margin: "0px" }}
                            >
                              <Form.Label>{t("Address")}</Form.Label>

                              <div
                                className={`doctor-address ${
                                  lang === "EN" ? "eng-text" : ""
                                }`}
                              >
                                <Form.Label>{combineAddress}</Form.Label>
                                <Button onClick={() => handleEditInputField()}>
                                  {t("Edit")}
                                </Button>
                              </div>
                            </Form.Group>
                          )}
                        </>
                      )}

                      {/* ################### doctor and patient meta details IsSam ######################*/}

                      <div className="abc">
                        <input
                          type="checkbox"
                          defaultChecked={data?.usedOnRegularBasis}
                          name="usedOnRegularBasis"
                          onChange={(e) => handleinputchange(e, i)}
                        />
                        <span>
                          {" "}
                          {t("This patient uses the drag on a regualer basis")}
                        </span>
                      </div>
                      <div className="abc">
                        <input
                          defaultChecked={data?.chronic}
                          type="checkbox"
                          name="chronic"
                          onChange={(e) => handleinputchange(e, i)}
                        />
                        <span> {t("The drag is cronic")}</span>
                      </div>
                      <Form.Group>
                        <Form.Label> {t("Remarks")}</Form.Label>
                        <textarea
                          className="form-control"
                          id="remarks"
                          name="remarks"
                          defaultValue={data ? data.remarks : ""}
                          onChange={(e) => handleinputchange(e, i)}
                          placeholder={t("Remarks")}
                          autoComplete="off"
                        />
                      </Form.Group>
                      <br></br>
                      {prescriptionData.length - 1 === i && (
                        <Button
                          onClick={() => handleaddclick()}
                          className="buttton_content"
                          tabIndex={-1}
                        >
                          {t("Add More")}
                        </Button>
                      )}
                      {prescriptionData.length != 0 && (
                        <Button
                          variant="danger"
                          className="removebuttton_content"
                          onClick={() => handleremove(data)}
                          style={{ margin: " 0 10px" }}
                          tabIndex={-1}
                        >
                          {t("Remove")}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <Button
                onClick={() => handleaddclick()}
                className="buttton_content"
                tabIndex={-1}
              >
                {t("Add New")}
              </Button>
            )}
          </Form>
        </>
      )}
      <div className="two_button">
        <div className="two_button-left">
          <Button
            variant="primary"
            size="lg"
            className="buttton_content save_sammary_press"
            onClick={signandsend}
            tabIndex={-1}
            disabled={
              (key === "Prescription" && prescriptionData?.length === 0) ||
              (key === "Summary" && !isNewValue)
            }
          >
            {t("Sign and send summary to patient")}
          </Button>
          <div>
            <span
              onClick={uploadSummary}
              className="sign_sammary_press"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t("Save Summary")}
            </span>
          </div>
          <div className="modal-close-btn">
            <button
              onClick={() => {
                props.handleClose();
                updateWithClose();
              }}
            >
              {t("Close")}
            </button>
          </div>
        </div>
        <div className="two_button-right">
          <div className="note-text">
            <span> {t("Changes will be automatically saved")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerSummaryComponent;

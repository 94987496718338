import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Dashborad/client.css";
import ViewDescription from "./ViewDescription";
import moment from "moment";
import ViewSurvey from "./ViewSurvey";
import CommentModal from "./CommentModal";
import { useTranslation } from "react-i18next";
import SummaryComponent from "./SummaryComponent";
import { CheckIcon } from "../common/icon";

const DoctorPast = (props) => {
  const { t } = useTranslation();
  const [summaryId, setSummaryId] = useState();
  const [description, setDescription] = useState();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const handleClose = () => setDescriptionOpen(false);
  const [updateSummaryResponce, setUpdateSummaryResponse] = useState({});
  const [prescriptionResponce, setUpdateProscriptionResponse] = useState([]);
  const [mid, setMId] = useState();
  const [cdata, setCdata] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState(props?.allAppointment);
  const [userName, setUserName] = useState("");
  const [checkIDF, setCheckIDF] = useState("");
  const [currentUserData, setCurrentUserData] = useState();
  const [doctorData, setDoctorData] = useState();
  const [hasMeetingType, setHasMeetingType] = useState(false);

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const summaryModal = (e, id, prescription, summary, data) => {
    e.preventDefault();
    setCurrentUserData(data?.userDetails);
    setDoctorData(data?.doctorDetails);
    setUserName(
      `${data?.userDetails?.first_name}  ${data?.userDetails?.last_name}`
    );
    setCheckIDF(data?.consultation[0]?.meetingGroupId);
    setSummaryId(id);
    setUpdateSummaryResponse(summary);
    setUpdateProscriptionResponse(prescription);
    setHasMeetingType(Object.prototype.hasOwnProperty.call(data, 'meetingType'));
    props.setOpen(true);
  };

  const descriptionModal = (e, d) => {
    e.preventDefault();
    setDescription(d);
    setDescriptionOpen(true);
  };

  function ageCalculator(d) {
    var birthDate = new Date(d);
    var otherDate = new Date();

    var years = otherDate.getFullYear() - birthDate.getFullYear();

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() == birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }
    return years;
  }
  const handleCloseSumm = () => {
    props.handleCloseSummary();
    setUpdateSummaryResponse({});
  };
  const handleFilterDate = (e) => {
    setStartDate(e[0]);
    setEndDate(e[1]);
    handleFilterDateData(e);
  };
  const handleFilterDateData = (e) => {
    let datefliterData = props?.allAppointment.filter((x) => {
      const appntDt = new Date(x?.scheduledDate);
      appntDt.setHours(0);
      appntDt.setMinutes(0);
      appntDt.setSeconds(0);
      appntDt.setMilliseconds(0);
      const startTime = new Date(e[0]).getTime();
      const appntTime = appntDt.getTime();
      const endTime = new Date(e[1]).getTime();
      return appntTime >= startTime && appntTime <= endTime;
    });
    setFilterData(datefliterData);
    if (
      (e[0] === null && e[1] === null) ||
      (e[0] === "" && e[1] === "") ||
      (e[0] === undefined && e[1] === undefined)
    ) {
      setFilterData(props.allAppointment);
    }
  };

  useEffect(() => {
    setFilterData(props.allAppointment);
  }, [props.allAppointment]);

  useEffect(() => {
    if (startDate && endDate) {
      handleFilterDateData([startDate, endDate]);
    }
  }, [props?.allAppointment]);

  return (
    <div>
      <SummaryComponent
        open={props.open}
        updateSummaryResponce={updateSummaryResponce}
        setUpdateSummaryResponse={setUpdateSummaryResponse}
        prescriptionResponce={prescriptionResponce}
        setOpen={props.setOpen}
        meetingId={summaryId}
        handleClose={handleCloseSumm}
        AllApoinmentDoctor={props.AllApoinmentDoctor}
        setMeetingValue={props.setMeetingValue}
        meetingValue={props.meetingValue}
        userName={userName}
        checkIDF={checkIDF}
        userData={currentUserData}
        doctorData={doctorData}
        hasMeetingType={hasMeetingType}
      />
      <ViewDescription
        description={description}
        descriptionOpen={descriptionOpen}
        handleClose={handleClose}
      />
      <ViewSurvey
        medicalq={props.medicalq}
        handleClose={props.handleClose5}
        qData={props.qData}
      />
      <CommentModal
        handleClose={props.handleClose2}
        open={props.comentModal}
        mid={mid}
        cdata={cdata}
        note={props.note}
        setNote={props.setNote}
      />

      <section className="userWrapper doctor-user">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-date-picker">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(e) => handleFilterDate(e)}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  maxDate={new Date()}
                  selectsRange
                  isClearable
                  showIcon
                  className="custom-date-range"
                  placeholderText="Click to select  dates"
                  showPopperArrow={false}
                  locale="he"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="userInnerWrapper userInnerWrapB">
                {props.loader ? (
                  <h2>{t("Please wait")} ...</h2>
                ) : (
                  <ul>
                    {filterData.length !== 0 ? (
                      filterData &&
                      filterData
                        .sort(
                          (objA, objB) =>
                            new Date(objB.scheduledDate) -
                            new Date(objA.scheduledDate)
                        )
                        .map((data, i) => {
                          return (
                            <li key={i}>
                              <div className="cfBase">
                                <div className="cfFirst">
                                  <div className="cfImgbg">
                                    <div className="cfImgTxt">
                                      <h3>{t("Patient")}</h3>
                                      <h2>
                                        {data?.patientDetails
                                          ? data?.patientDetails.first_name
                                          : ""}{" "}
                                        {data?.patientDetails
                                          ? data?.patientDetails.last_name
                                          : ""}
                                      </h2>
                                      <h4>
                                        {t("Age")}-
                                        {data?.patientDetails?.patientDOB
                                          ? ageCalculator(
                                            data.patientDetails.patientDOB
                                          )
                                          : t("N/A")}
                                      </h4>
                                      <h4>
                                        {t("Gender")}-
                                        {t(data?.patientDetails?.patientGender)}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="cfDate">
                                    <h3>
                                      {moment(data?.scheduledDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </h3>
                                    <span>
                                      {moment(data?.scheduledDate).format(
                                        "HH:mm"
                                      )}
                                    </span>
                                    <span>
                                      {" "}
                                      {t(
                                        weekday[
                                        new Date(data?.scheduledDate).getDay()
                                        ]
                                      )}
                                    </span>
                                    {data?.isFollowUp ? (
                                      <h4 className="followup-text">
                                        {t("Follow Up Meeting")}
                                      </h4>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="cflast">
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.summary ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active">
                                            {" "}
                                            {t("Summary/Prescription")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {" "}
                                          {t("Summary/Prescription")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.summary ? (
                                      <button
                                        className="active"
                                        onClick={(e) =>
                                          summaryModal(
                                            e,
                                            data._id,
                                            data?.prescription,
                                            data?.summary,
                                            data
                                          )
                                        }
                                      >
                                        {t("update")}
                                      </button>
                                    ) : (
                                      <button
                                        className="active"
                                        style={{
                                          background: "gray",
                                          width: "75px",
                                        }}
                                        onClick={(e) =>
                                          summaryModal(
                                            e,
                                            data._id,
                                            data?.prescription,
                                            data?.summary,
                                            data
                                          )
                                        }
                                      >
                                        {t("not ready")}
                                      </button>
                                    )}
                                  </div>
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.requestDescription ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active">
                                            {" "}
                                            {t("Request description")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {" "}
                                          {t("Request description")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.requestDescription ? (
                                      <button
                                        className="active"
                                        onClick={(e) =>
                                          descriptionModal(
                                            e,
                                            data.requestDescription
                                          )
                                        }
                                      >
                                        {t("View")}
                                      </button>
                                    ) : (
                                      <button
                                        className="active"
                                        style={{
                                          background: "gray",
                                          width: "75px",
                                        }}
                                        onClick={(e) =>
                                          summaryModal(e, data._id)
                                        }
                                      >
                                        {t("not ready")}
                                      </button>
                                    )}
                                  </div>
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.survey?.mainComplaint ||
                                        data.survey?.medicalHistory ||
                                        data.survey?.medications ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active ">
                                            {t("Medical questionnaire")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {t("Medical questionnaire")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.survey?.mainComplaint ||
                                      data.survey?.medicalHistory ||
                                      data.survey?.medications ? (
                                      <button
                                        className="active"
                                        onClick={() => (
                                          props.setMedicalQ(true),
                                          props.setQData(data.survey)
                                        )}
                                      >
                                        {t("View")}
                                      </button>
                                    ) : (
                                      <button
                                        className="active"
                                        style={{
                                          background: "gray",
                                          width: "75px",
                                        }}
                                      >
                                        {t("not ready")}
                                      </button>
                                    )}
                                  </div>
                                  <div className="cfInfo">
                                    {data?.comments ? (
                                      <div className="cfChk">
                                        <CheckIcon />
                                        <h3 className="active ">
                                          {t("Meeting comments")}
                                        </h3>
                                      </div>
                                    ) : (
                                      <div className="cfChk">
                                        <h3 className="active no_document">
                                          {t("Meeting comments")}
                                        </h3>
                                      </div>
                                    )}
                                    {data?.comments ? (
                                      <button
                                        className="active"
                                        onClick={() => (
                                          props.setCommentModal(true),
                                          setMId(data._id),
                                          setCdata(data.comments.doctorComment),
                                          props.setNote(
                                            data?.comments?.doctorComment
                                          )
                                        )}
                                      >
                                        {t("update")}
                                      </button>
                                    ) : (
                                      <button
                                        className="active"
                                        style={{
                                          background: "gray",
                                          width: "75px",
                                        }}
                                        onClick={() => (
                                          props.setCommentModal(true),
                                          setMId(data._id)
                                        )}
                                      >
                                        {t("add")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                    ) : (
                      <h3 className="no_meeting">
                        {t("You have no past meeting")}
                      </h3>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default DoctorPast;

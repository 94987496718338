import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../My Queues/queues.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const AllDocumentModel = (props) => {
  const { t } = useTranslation();

  const [zoom, setZoom] = useState(1);

  const iframeContainerRef = useRef(null); // Ref to the container for zoom

  const handleZoomIn = () => {
    const container = iframeContainerRef.current;
    if (container) {
      const prevZoom = zoom;
      const newZoom = Math.min(prevZoom + 0.2, 5);
      setZoom(newZoom);
      const zoomRatio = newZoom / prevZoom;

      container.scrollLeft =
        container.scrollLeft * zoomRatio +
        (container.clientWidth * (zoomRatio - 1)) / 2;
      container.scrollTop =
        container.scrollTop * zoomRatio +
        (container.clientHeight * (zoomRatio - 1)) / 2;
    }
  };

  const handleZoomOut = () => {
    const container = iframeContainerRef.current;
    if (container) {
      const prevZoom = zoom;
      const newZoom = Math.max(prevZoom - 0.2, 0.5);
      setZoom(newZoom);
      const zoomRatio = newZoom / prevZoom;

      container.scrollLeft =
        container.scrollLeft * zoomRatio +
        (container.clientWidth * (zoomRatio - 1)) / 2;
      container.scrollTop =
        container.scrollTop * zoomRatio +
        (container.clientHeight * (zoomRatio - 1)) / 2;
    }
  };

  return (
    <div>
      <Modal
        open={props.openModel}
        onClose={() => {
          props.handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="summaryModal doctor-summary doc-list-modal"
      >
        <MDBCard style={{ width: "100%", height: "100%", overflowY: "auto" }}>
          <MDBCardBody>
          {props?.docURL?.type?.split("/")[0] === "image" && (
              <div className="zoom-controls">
                <Button onClick={handleZoomOut} disabled={zoom <= 0.5}>
                  {t("Zoom Out")}
                </Button>
                <Button onClick={handleZoomIn} disabled={zoom >= 3}>
                  {t("Zoom In")}
                </Button>
              </div>
            )}
            <div className="doc-grid">
              {" "}
              <Button
                disabled={props.docIndex === 0 || props.loading}
                onClick={() => props.handlePreDoc()}
                title={t("Previous")}
              >
                <i class="las la-angle-right"></i>
              </Button>
              {props.loading ? (
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="48"
                  visible={props.loading}
                />
              ) : (
                // <Button
                //   onClick={() => window.open(props.docURL.view, "_blank")}
                // >
                //   {" "}
                //   {t("View")}
                // </Button>
                <div className="document-iframe-grid"  
                ref={iframeContainerRef}
                style={{
                  height: "85vh",
                  position: "relative",
                }}>
                  {props?.docURL?.type === "pdf" ||
                  props?.docURL?.type === "application/pdf" ? (
                    <iframe
                      src={props.docURL.view}
                      title="Document"
                      className="document-iframe"
                      id="myiFrame"
                    />
                  ) : (
                    <iframe
                    srcDoc={`<html><body><img  style="max-width: 100%; height: 100%; max-height: 97vh;  margin: 0px auto;display: block; transform: scale(${zoom}); transform-origin: 0% 0%; " src=${props.docURL.view} alt="doc" /></body></html>`}
                      title="Document"
                      className="document-iframe"
                      id="myiFrame"
                    />
                  )}
                </div>
              )}
              <Button
                disabled={
                  props.documentLength - 1 === props.docIndex || props.loading
                }
                onClick={() => props.handleNextDoc()}
                title={t("Next")}
              >
                <i class="las la-angle-left"></i>
              </Button>
            </div>
            <div className="doc-count">
              <h6>{`${props.docIndex + 1} מתוך ${props.documentLength}`}</h6>
              <Button
                sm="lg"
                className="buttton_content"
                onClick={() => props.handleClose()}
              >
                {" "}
                {t("Close")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};

export default AllDocumentModel;

import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../My Queues/queues.css";
import "react-image-crop/dist/ReactCrop.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { Network } from "../../config/axios";
import "react-quill/dist/quill.snow.css";
import GeneratePdf from "../../My Queues/GeneratePdf";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import pdfIcon from "../../assets/images/icons/pdf.png";
import imageIcon from "../../assets/images/icons/image-icon.png";

import $ from "jquery";
import {
  doctorSetPrescriptionSettings,
  userGetUserProfile,
  doctorGetTemplate,
  doctorSaveTemplate,
  doctorUpdateTemplate,
  documentView,
} from "../../config/service";
import axios from "axios";
import { serverPath } from "../../config/key";
import AddFieldModal from "./AddFieldModal";
import DeleteFieldModal from "./DeleteFieldModal";
import "./CreatePrescription.css";
import AddTemplateModal from "./AddTemplate";
import DeleteTemplateModal from "./DeleteTemplateModal";
import TemplateSearchList from "./SearchedList";
import AllDocumentModel from "../DoctorDashboard/AllDocumentModel";

let typing = false;

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#000",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 20px",
    lineHeight: "16px",
    cursor: "pointer",
    borderRadius: "0px",
    ":hover": {
      background: "#1E0F3C",
      borderRadius: "5px",
      color: "#E9E7EC",
    },
  }),
  control: () => ({
    background: "transparent",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    color: "#000",
    display: "flex",
    alignItem: "center",
    height: "43px",
    margin: "0px",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "500",
    ":hover": {
      background: "transparent",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "500",
    fontSize: "18px",
    color: "#000",
  }),
  input: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "500",
    fontSize: "16px !important",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 20px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "20px",
    color: "#858585c7",
  }),
};

const RxSummaryComponent = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [key, setKey] = useState("Summary");
  const lang = localStorage.getItem("lang");
  const userId = localStorage.getItem("userId");
  const [value, setValue] = useState(null);
  const [formValue, setformValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    phone: "",
    clientGender: "",
    age: "",
    clientDOB: "",
  });
  const initialFieldArray = [
    {
      name: "referral_reason",
      value: "referral_reason",
      label: t("Reason for referral"),
    },
    {
      name: "background_details",
      value: "background_details",
      label: t("Background Details"),
    },
    { name: "diagnsis", value: "diagnsis", label: t("Diagonosis") },
    {
      name: "treatment_program",
      value: "treatment_program",
      label: t("Treatment Program"),
    },
  ];

  const [payloadData, setPayloadData] = useState("");

  const [prescriptionData, setPrescriptionData] = useState([]);
  const [generateUpdateModal, setGenerateupdateModal] = useState(false);
  const token = localStorage.getItem("token");
  const [summary, setSummary] = useState({
    background_details: "",
    diagnsis: "",
    referral_reason: "",
    treatment_program: "",
  });
  const [isNewValue, setisNewValue] = useState(true);
  const [errors, setErrors] = useState({});

  const handleClose4 = () => setGenerateupdateModal(false);
  const [medicineList, setMedicineList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedSec, setSelectedSec] = useState(-1);
  const [medicineValue, setMedicineValue] = useState("");
  const [doctorData, setDoctorData] = useState("");
  const [medicineMetaDocData, setMedicineMetaDocData] = useState("");

  const [consultId, setConsultId] = useState("");
  const [consultField, setConsultField] = useState();
  const [doctorObj, setDoctorObj] = useState();
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [addTemplate, setAddTemplate] = useState({
    templateName: "",
    templateText: "",
    templateField: [],
  });
  const [templateList, setTemplateList] = useState([]);
  const [isEditTemp, setIsEditTemp] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [deleteTempModal, setDeleteTempModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filteredTemplate, setFilteredTemplate] = useState([]);
  const [targetInput, setTargetInput] = useState("");
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [deleteFieldModal, setDeleteFieldModal] = useState(false);
  const [addField, setAddField] = useState({
    fieldName: "",
    fieldPosition: "",
    fieldPermanentStatus: "",
  });
  const [additionalData, setAdditionalData] = useState({});

  const [summaryFieldArray, setSummaryFieldArray] = useState(initialFieldArray);
  const [cleared, setCleared] = React.useState(false);
  const [rxMeetingId, setRxMeetingId] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docIndex, setDocIndex] = useState();
  const [docURL, setDocURL] = useState("");
  const [allPdfDocument, setAllPdfDocument] = useState([]);
  const [showInputField, setShowInputField] = useState(false);
  const [showPatientInputField, setShowPatientInputField] = useState(false);
  const [combineAddress, setCombineAddress] = useState("");
  const [combinePatientAddress, setPatientCombineAddress] = useState("");
  const [medicMetaDetails, setMedicMetaDetails] = useState({});
  const [prescriptionIndex, setPrescriptionIndex] = useState();

  const handleCheckDoctorAddress = () => {
    const fullAddress = medicineMetaDocData?.clientAddress;
    if (
      fullAddress?.city &&
      fullAddress?.house &&
      fullAddress?.pincode &&
      fullAddress?.street
    ) {
      setShowInputField(true);
      setCombineAddress(
        medicineMetaDocData.phone +
          " " +
          fullAddress?.street +
          " " +
          fullAddress?.house +
          " " +
          fullAddress?.city +
          " " +
          fullAddress?.pincode
      );
    } else {
      setShowInputField(false);
    }
  };

  const handleCheckPatientAddress = () => {
    if (
      formValue?.patient_city &&
      formValue?.patient_houseno &&
      formValue?.patient_pincode &&
      formValue?.patient_street
    ) {
      setShowPatientInputField(true);
      setPatientCombineAddress(
        formValue.phone +
          " " +
          formValue?.patient_street +
          " " +
          formValue?.patient_houseno +
          " " +
          formValue?.patient_city +
          " " +
          formValue?.patient_pincode
      );
      if (
        medicineValue &&
        Object.keys(medicineValue).length > 0 &&
        prescriptionIndex >= 0
      ) {
        enableMedicForm(medicineValue, prescriptionIndex);
      }
    } else {
      setShowPatientInputField(false);
    }
  };

  const handleEditInputField = () => {
    setShowInputField(false);
  };

  const handlePatientEditInputField = () => {
    setShowPatientInputField(false);
  };

  useEffect(() => {
    handleCheckDoctorAddress();
  }, [medicineMetaDocData?.clientAddress]);

  useEffect(() => {
    handleCheckPatientAddress();
  }, [formValue]);

  React.useEffect(() => {
    if (cleared) {
      setValue(null);
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [cleared]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { meetingDetail } = location.state || {};
  useEffect(() => {
    if (meetingDetail) {
      const patientData = meetingDetail?.userDetails;
      setformValue({
        ...formValue,
        first_name: patientData?.first_name,
        last_name: patientData?.last_name,
        email: patientData?.email,
        id: patientData?.id,
        phone: patientData?.phone,
        clientGender: patientData?.clientGender,
        age: meetingDetail?.patientAge,
      });
      setConsultId(meetingDetail?.consultingFieldId);
      setAllPdfDocument(meetingDetail?.documents);
      const parsedDate = moment(patientData.clientDOB);
      setValue(parsedDate);
    }
  }, [meetingDetail]);

  const continuingCare = [
    "הטיפול הסתיים",
    "הופנה לביצוע בדיקות",
    "לא מתאים לאונליין - הופנה לטיפול פרונטלי",
    "הזמנה להמשך מעקב פרונטלי",
    "הזמנה להמשך מעקב אונליין",
  ];
  const quantityArray = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  const timesArray = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  // const genderArr = [
  //   { value: "Male", label: t("Male") },
  //   { value: "Female", label: t("Female") },
  // ];

  const handlePeriodArray = () => {
    let periodArray = [];
    for (let i = 1; i <= 180; i++) {
      periodArray.push({ value: i, label: i });
    }
    return periodArray;
  };
  const frequencyArray = [
    { value: "day", label: t("day") },
    { value: "week", label: t("week") },
    // { value: "month", label: t("month") },
  ];
  const durationArray = [
    { value: "day", label: t("days") },
    { value: "week", label: t("weeks") },
    // { value: "month", label: t("months") },
  ];

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSelectedSec(-1);
          setSearchKey("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleMedicine = (value, name, index) => {
    setMedicineValue(value);
    setPrescriptionIndex(index);
    enableMedicForm(value, index);
    const copy = [...prescriptionData];
    copy[index]["name"] = value?.Name;
    copy[index]["medicineId"] = value?._id;
    setPrescriptionData(copy);
    if (name === "inputVal" && medicineValue === "") {
      setSelectedSec(index);
    }
    if (name === "listVal" || value === "") {
      setSelectedSec(-1);
    }
  };

  const enableMedicForm = (value, index) => {
    const isSamObj = medicMetaDetails;
    isSamObj[index] = value?.IsSam;
    setMedicMetaDetails(isSamObj);
    const prescribeData = [...prescriptionData];
    prescribeData[index]["isSam"] = value?.IsSam;
    if (value?.IsSam) {
      prescribeData[index]["patient_street"] = formValue?.patient_street || "";
      prescribeData[index]["patient_houseno"] =
        formValue?.patient_houseno || "";
      prescribeData[index]["patient_city"] = formValue?.patient_city || "";
      prescribeData[index]["patient_pincode"] =
        formValue?.patient_pincode || "";
      prescribeData[index]["patient_dob"] = formValue?.clientDOB
        ? moment(formValue?.clientDOB)
        : moment(new Date());
      prescribeData[index]["patient_gender"] = formValue?.clientGender || "";
      prescribeData[index]["doctor_phone"] = medicineMetaDocData?.phone || "";
      prescribeData[index]["doctor_street"] =
        medicineMetaDocData?.clientAddress?.street || "";
      prescribeData[index]["doctor_city"] =
        medicineMetaDocData?.clientAddress?.city || "";
      prescribeData[index]["doctor_houseno"] =
        medicineMetaDocData?.clientAddress?.house || "";
      prescribeData[index]["doctor_pincode"] =
        medicineMetaDocData?.clientAddress?.pincode || "";
    } else {
      delete prescribeData[index]["patient_street"];
      delete prescribeData[index]["patient_houseno"];
      delete prescribeData[index]["patient_city"];
      delete prescribeData[index]["patient_pincode"];
      delete prescribeData[index]["patient_dob"];
      delete prescribeData[index]["patient_gender"];
      delete prescribeData[index]["doctor_phone"];
      delete prescribeData[index]["doctor_street"];
      delete prescribeData[index]["doctor_city"];
      delete prescribeData[index]["doctor_houseno"];
      delete prescribeData[index]["doctor_pincode"];
    }

    setPrescriptionData(prescribeData);
  };

  const onDateChange = (value) => {
    if (value) {
      setValue(value);
      const isoDate = value.toISOString();
      // var today = new Date();
      // var birthDate = new Date(value);
      // var age_now = today.getFullYear() - birthDate.getFullYear();

      // var m = today.getMonth() - birthDate.getMonth();
      // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      //   age_now--;
      // }
      setformValue({
        ...formValue,
        dob: isoDate,
        clientDOB: isoDate,
        age: "",
      });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;

    if (name === 'id' || name === 'first_name' ) {
      newValue = value.replace(/\s/g, "");
    }

    if (name === "phone") {
      if (/^[0-9+ -]*$/.test(value)) {
        setformValue({ ...formValue, [name]: newValue });
      }
    } else
      setformValue({
        ...formValue,
        [name]: newValue,
      });
  };
  const handleChangeConsultId = (event) => {
    setConsultId(event.target.value);
  };

  useEffect(() => {
    if (formValue.id && formValue.id.length >= 9) {
      getUserDataById(formValue.id);
    }
  }, [formValue.id]);

  useEffect(() => {
    let isValid = false;
    for (let key of Object.keys(summary)) {
      if (summary[key]) {
        isValid = true;
        break;
      }
    }
    setisNewValue(isValid);
  }, [summary]);
  useEffect(() => {
    allMedicine(searchKey || "a");
  }, [props.meetingId, searchKey]);

  const handleinputchange = (e, index) => {
    // if (e.target) {
    //   const { name, value } = e.target;
    //   const list = prescriptionData;
    //   if (name == "chronic" || name == "usedOnRegularBasis") {
    //     const checked = e.target.checked;
    //     list[index][name] = checked;
    //   } else {
    //     list[index][name] = value;
    //   }
    //   setPrescriptionData(list);
    // } else {
    //   const list = prescriptionData;
    //   list[index]["medicineId"] = e._id;
    //   list[index]["name"] = e.Name;
    //   setPrescriptionData(list);
    // }

    setPrescriptionData((prevState) => {
      const updatedData = [...prevState];
      if (e.target) {
        const { name, value } = e.target;
        if (name === "chronic" || name === "usedOnRegularBasis") {
          const checked = e.target.checked;
          updatedData[index] = {
            ...updatedData[index],
            [name]: checked,
          };
        } else if (name === "doctor_phone" && /^[0-9+ -]*$/.test(value)) {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
          };
        } else if (name !== "doctor_phone" && name !=="dosage") {
          updatedData[index] = {
            ...updatedData[index],
            [name]: value,
          };
        }
        else if (name ===  "dosage") {
          const finalValue = Number(value);
          updatedData[index] = {
            ...updatedData[index],
            [name]: finalValue,
          };
        }
      }
       else {
        updatedData[index] = {
          ...updatedData[index],
          medicineId: e._id,
          name: e.Name,
        };
      }

      return updatedData;
    });
  };

  const handleremove = (data) => {
    const updatedPrescriptionData = prescriptionData.filter((d) => d !== data);
    setPrescriptionData(updatedPrescriptionData);
    const updatedMedicMetaDetails = {};

    updatedPrescriptionData.forEach((item, index) => {
      updatedMedicMetaDetails[index] = item?.isSam;
    });
    setMedicMetaDetails(updatedMedicMetaDetails);
    setErrors({});
  };
  const handleaddclick = () => {
    setPrescriptionData([
      ...prescriptionData,
      {
        medicineId: "",
        name: "",
        quantity: "",
        frequency: "",
        frequencyUnit: "",
        duration: "",
        durationUnit: "",
        dosage: "",
        remarks: "",
        chronic: false,
        usedOnRegularBasis: false,
      },
    ]);
    setMedicineValue({});
    setSearchKey("");
  };

  const allMedicine = async (data) => {
    if (data) {
      try {
        const response = await Network().get("/doctor/medications/" + data, {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        });

        setMedicineList(response.data.medication);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const userDetails = async () => {
    const data = {
      userId: userId,
    };
    await userGetUserProfile(data).then((response) => {
      if (response) {
        let result = response?.data?.profileDetails[0];
        if (result !== undefined && result !== null) {
          setMedicineMetaDocData(result);
          setDoctorData(result?.doctorData[0]);
          if (result.prescriptionFields && result.prescriptionFields[0]) {
            setAddField({
              fieldName: result?.prescriptionFields[0].label,
              fieldPosition: result?.prescriptionFields[0].order,
              fieldPermanentStatus:
                result?.prescriptionFields[0].fieldPermanentStatus,
            });
            setAdditionalData(result.prescriptionFields[0]);
            handleProfileFieldData(result.prescriptionFields[0]);
          }
          let consultingFieldsArr =
            result?.doctorData[0]?.consultationSpeciality;
          setConsultField(consultingFieldsArr);
        }
      }
    });
  };

  const doctorDataById = async (docId) => {
    try {
      const response = await axios.get(
        `${serverPath}/users/getDoctorsById/${docId}`
      );

      const {
        data: { statusCode, doctor },
      } = response || {};
      if (statusCode === 200) {
        setDoctorObj(doctor[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    doctorDataById(userId);
    if (userId) {
      userDetails();
    }
  }, [userId]);

  useEffect(() => {
    if (meetingDetail) {
      setConsultId(meetingDetail?.consultingFieldId);
    } else if (
      doctorData?.consultationSpeciality &&
      doctorData?.consultationSpeciality.length === 1
    ) {
      setConsultId(doctorObj?.consultationSpeciality[0]);
    }
  }, [doctorData, meetingDetail, doctorObj]);

  const getUserDataById = async (id) => {
    if (id) {
      try {
        const response = await Network().get("/doctor/getUserDataById/" + id, {
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        });

        const { result, statusCode } = response.data;
        if (statusCode === 200) {
          const isoDate = result?.dob;
          const parsedDate = moment(isoDate);
          setValue(parsedDate);
          // var today = new Date();
          // var birthDate = new Date(isoDate);
          // var age_now = today.getFullYear() - birthDate.getFullYear();

          // var m = today.getMonth() - birthDate.getMonth();
          // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          //   age_now--;
          // }

          setformValue({
            ...formValue,

            first_name: result?.firstName,
            last_name: result?.lastName,
            email: result?.email,
            phone: result?.phone,
            clientGender: result.gender,
            dob: isoDate,
            age: "",
            clientDOB: moment(result?.dob),
            patient_street: result.clientAddress?.street,
            patient_houseno: result.clientAddress?.house,
            patient_city: result.clientAddress?.city,
            patient_pincode: result.clientAddress?.pincode,
          });
        }
      } catch (error) {
        console.log(error);
        setValue(value);
        setformValue({
          ...formValue,
          id: formValue.id,
        });
      }
    }
  };

  let isValid;
  const validation = () => {
    isValid = true;
    const errorObj = {};
    let toastShown = false; // Flag to indicate if a toast has already been shown

    prescriptionData.forEach((d, index) => {
      if (!errorObj[index]) {
        errorObj[index] = [];
      }

      const showToast = (message) => {
        if (!toastShown) {
          toast.error(t(message));
          toastShown = true;
        }
      };

      if (!d.name) {
        showToast("Medicine name is a mandatory field");
        errorObj[index].push("name");
        isValid = false;
      }
      if (!d.quantity) {
        showToast("Quantity is a mandatory field");
        errorObj[index].push("quantity");
        isValid = false;
      }
      let medicMetaDetailsError = false;
      if (medicMetaDetails[index]) {
        if (!d.patient_street) {
          //showToast("Patient street address is a mandatory field");
          errorObj[index].push("patient_street");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.patient_houseno) {
          //showToast("Patient house no is a mandatory field");
          errorObj[index].push("patient_houseno");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.patient_city) {
          //showToast("Patient city is a mandatory field");
          errorObj[index].push("patient_city");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.patient_pincode) {
          //showToast("Patient pincode is a mandatory field");
          errorObj[index].push("patient_pincode");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.patient_dob) {
          //showToast("Patient DOB is a mandatory field");
          errorObj[index].push("patient_dob");
          isValid = false;
          medicMetaDetailsError = true;
        }
        // if (!d.patient_gender) {
        //   //showToast("Patient Gender is a mandatory field");
        //   errorObj[index].push("patient_gender");
        //   isValid = false;
        //   medicMetaDetailsError = true;
        // }
        if (!d.doctor_phone) {
          //showToast("Clinic phone no is a mandatory field");
          errorObj[index].push("doctor_phone");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.doctor_street) {
          //showToast("Doctor street is a mandatory field");
          errorObj[index].push("doctor_street");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.doctor_houseno) {
          //showToast("Doctor house no is a mandatory field");
          errorObj[index].push("doctor_houseno");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.doctor_city) {
          //showToast("Doctor city is a mandatory field");
          errorObj[index].push("doctor_city");
          isValid = false;
          medicMetaDetailsError = true;
        }
        if (!d.doctor_pincode) {
          //showToast("Doctor pincode is a mandatory field");
          errorObj[index].push("doctor_pincode");
          isValid = false;
          medicMetaDetailsError = true;
        }
      }

      if (medicMetaDetailsError) {
        showToast(t("Please fill the mendatory fields"));
      }

      if (!d.frequency) {
        showToast("Times is a mandatory field");
        errorObj[index].push("frequency");
        isValid = false;
      }
      if (!d.frequencyUnit) {
        showToast("Frequency is a mandatory field");
        errorObj[index].push("frequencyUnit");
        isValid = false;
      }
      if (!d.duration) {
        showToast("Period is a mandatory field");
        errorObj[index].push("duration");
        isValid = false;
      }
      if (!d.durationUnit) {
        showToast("Duration is a mandatory field");
        errorObj[index].push("durationUnit");
        isValid = false;
      }
      if (!d.dosage) {
        showToast("Dosage is a mandatory field");
        errorObj[index].push("dosage");
        isValid = false;
      }
    });

    return { isValid, errorObj };
  };

  let isValidate;

  const validate = () => {
    isValidate = true;
    if (!formValue.id) {
      isValidate = false;
      toast.warning(t("Please enter id number"));
      return;
    }
    if (!formValue.first_name) {
      isValidate = false;
      toast.warning(t("Please enter first name"));
      return;
    }
    if (!formValue.last_name) {
      isValidate = false;
      toast.warning(t("Please enter last name"));
      return;
    }

    // if (!formValue.email) {
    //   isValidate = false;
    //   toast.warning(t("Please enter email address"));
    //   return;
    // }

    // if (typeof formValue.email !== "undefined") {
    //   const pattern = new RegExp(
    //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    //   );
    //   if (!pattern.test(formValue.email)) {
    //     isValidate = false;
    //     toast.warning(t("Please enter valid email"));
    //     return;
    //   }
    // }
    if (!formValue.phone) {
      isValidate = false;

      toast.warning(t("Please enter mobile number"));
      return;
    }
    if (formValue.phone) {
      if (formValue.phone.length > 20) {
        isValidate = false;

        toast.warning(t("Mobile number max length 20"));
        return;
      }
    }
    if (!value) {
      isValidate = false;
      toast.warning(t("Please choose date of birth"));
      return;
    }
    if (!value._isValid) {
      isValidate = false;
      toast.warning(t("Please fill correct date of birth"));
      return;
    }

    if (!consultId) {
      isValidate = false;
      toast.warning(t("Please select consultation field"));
      return;
    }
    if (medicMetaDetails && Object.keys(medicMetaDetails).length > 0) {
      if (!formValue.clientGender) {
        isValidate = false;
        toast.warning(t("Patient Gender is a mandatory field"));
        return;
      }
    }

    return isValidate;
  };
  const handleSummarychange = (e, key) => {
    const { value } = e.target;
    setSummary({ ...summary, [key]: value });
    if (value === "#") {
      handleFilteredTemp(key);
      setTargetInput(key);
    }
    if (rxMeetingId) {
      typing = true;
    }
  };

  const uploadSummary = async (e) => {
    e.preventDefault();
    const removeProperties = (obj, propsToRemove) => {
      const newObj = { ...obj };
      propsToRemove.forEach((prop) => delete newObj[prop]);
      return newObj;
    };

    // Exclude these properties from formValue
    const excludedProperties = [
      "patient_street",
      "patient_houseno",
      "patient_city",
      "patient_pincode",
    ];

    const updatedFormValue = removeProperties(formValue, excludedProperties);

    let data = {
      requestDescription: "",
      service: "video",
      consultingFieldId: consultId,
      isUrgent: false,
      termsAndConditions: true,
      age: "",
      issueInvoice: false,
      ...updatedFormValue,
      refferalId: "",
      ...summary,
      prescription: prescriptionData,
      continuingCare: "",
    };
    let newFieldValue;
    if (addField.fieldName && addField.fieldPermanentStatus) {
      const summaryKeys = Object.keys(summary);
      if (summaryKeys.includes(addField.fieldName)) {
        newFieldValue = summary[addField.fieldName];
      }
      data.prescriptionFields = {
        name: addField.fieldName,
        position: addField.fieldPosition,
        data: newFieldValue ? newFieldValue : "",
      };
    }
    validate();
    const { isValid, errorObj } = validation();
    if (!isValid) {
      setErrors(errorObj);
      return;
    }
    setPayloadData(data);
  };

  const signandsend = async (e) => {
    uploadSummary(e);
    if (isValid === true && isValidate === true) {
      setGenerateupdateModal(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      let element = document.querySelector(".red-border");
      if (element) {
        element?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      setTimeout(() => {
        setErrors({});
      }, 20000);
    }
  }, [Object.keys(errors).length > 0]);

  const searchMedicine = (event) => {
    if (event.code.includes("Key")) {
      setSearchKey((old) => old + event.code.replace("Key", "").toUpperCase());
    } else if (event.code.includes("Backspace")) {
      setSearchKey((old) => {
        return old.substring(0, old.length - 1);
      });
    } else {
      setSearchKey(event.target.value);
    }
  };

  const handleListModal = (index) => {
    setSelectedSec(index);
  };

  const handleTemplateChange = (event) => {
    const { name, value } = event.target;
    setAddTemplate({
      ...addTemplate,
      [name]: value,
    });
  };
  const handleMultiChange = (value) => {
    setAddTemplate({
      ...addTemplate,
      ["templateField"]: value,
    });
  };

  let isTemplateValidate;

  const templateValidation = () => {
    isTemplateValidate = true;
    if (!addTemplate.templateName) {
      isTemplateValidate = false;
      toast.warning(t("Please enter template name"));
      return;
    }
    if (!addTemplate.templateText) {
      isTemplateValidate = false;
      toast.warning(t("Please enter template text"));
      return;
    }
    if (addTemplate.templateField.length === 0) {
      isTemplateValidate = false;
      toast.warning(t("Please select field name"));
      return;
    }
    return isTemplateValidate;
  };

  const handleTemplateSubmit = async () => {
    const templateFieldArray = addTemplate.templateField.map(
      (item) => item.value
    );

    const data = {
      templateName: addTemplate.templateName,
      templateText: addTemplate.templateText,
      templateField: templateFieldArray,
    };

    const isValid = templateValidation();

    if (isValid) {
      try {
        const response = await doctorSaveTemplate(data);
        const { code, message } = response.data;
        if (code === 201) {
          toast.success(t(message));
          setShowTemplateModal(false);
          getTemplateList();
          setAddTemplate({
            templateName: "",
            templateText: "",
            templateField: [],
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getTemplateList = async () => {
    try {
      const response = await doctorGetTemplate();
      const { code, templates } = response.data;
      if (code === 200) {
        setTemplateList(templates);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTemplateList();
  }, []);

  const handleEditTemplate = (data) => {
    setIsEditTemp(true);
    setTemplateId(data._id);
    const valuesArray = data.templateField;
    const filterTemplateField = valuesArray.map((value) => {
      const initialField = initialFieldArray.find(
        (item) => item.value === value
      );
      if (initialField) {
        return { value, label: initialField.label };
      } else {
        return { value, label: value };
      }
    });

    setAddTemplate({
      templateName: data.templateName,
      templateText: data.templateText,
      templateField: filterTemplateField,
    });
  };

  const submitEditTemplate = async () => {
    const templateFieldArray = addTemplate.templateField.map(
      (item) => item.value
    );

    const data = {
      templateId: templateId,
      templateName: addTemplate.templateName,
      templateText: addTemplate.templateText,
      templateField: templateFieldArray,
    };

    const isValid = templateValidation();

    if (isValid) {
      try {
        const response = await doctorUpdateTemplate(data);
        const { code, message } = response.data;
        if (code === 201) {
          toast.success(t(message));
          setIsEditTemp(false);
          getTemplateList();
          setTemplateId("");
          setAddTemplate({
            templateName: "",
            templateText: "",
            templateField: [],
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseTempModal = () => {
    setIsEditTemp(false);
    setTemplateId("");
    setShowTemplateModal(false);
    setAddTemplate({
      templateName: "",
      templateText: "",
      templateField: [],
    });
  };

  const handleDeleteTemplate = async () => {
    try {
      const response = await Network().delete(
        `/doctor/deleteTemplate/${templateId}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      const { code, message } = response.data;
      if (code === 201) {
        getTemplateList();
        toast.success(t(message));
        setDeleteTempModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilteredTemp = (key) => {
    if (key) {
      const filteredTemplate = templateList.filter((item) =>
        item.templateField?.includes(key)
      );
      setFilteredTemplate(filteredTemplate);
      setShowSearch(true);
    }
  };

  const handleSelectTemp = (data) => {
    setSummary({ ...summary, [targetInput]: data.templateText });
    setShowSearch(false);
  };

  const handleAddTemplate = () => {
    setAddTemplate({
      templateName: "",
      templateText: "",
      templateField: [],
    });
    setIsEditTemp(false);
    setTemplateId("");
  };

  const handleCloseDeleteTemplate = () => {
    if (!isEditTemp) {
      setTemplateId("");
    }
    setDeleteTempModal(false);
  };

  const handleFieldModal = () => {
    setShowFieldModal(true);
  };
  const handleCloseFieldModal = () => {
    setShowFieldModal(false);
  };

  const handleFieldSubmit = async () => {
    const fieldStatus =
      addField.fieldPermanentStatus === "true" ||
      addField.fieldPermanentStatus === true;
    const data = {
      prescriptionFields: {
        label: addField.fieldName,
        fieldPermanentStatus: fieldStatus,
        order: parseInt(addField.fieldPosition),
      },
    };

    const isFieldValid = fieldValidation();

    if (isFieldValid && fieldStatus) {
      try {
        const response = await doctorSetPrescriptionSettings(data);
        const { code } = response.data;
        if (code === 200) {
          userDetails();
          setShowFieldModal(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (isFieldValid) {
      setShowFieldModal(false);
      handleProfileFieldData(data.prescriptionFields);
      if (additionalData.fieldPermanentStatus) {
        deletePrescriptionSetting(false);
        setAdditionalData({});
      }
    }
  };

  const handleProfileFieldData = (data) => {
    const newField = {
      name: data.label,
      value: data.label,
      label: data.label,
    };
    const newPosition = parseInt(data.order);
    const insertIndex = newPosition - 1;
    let copySummary = JSON.parse(JSON.stringify(initialFieldArray));

    setSummaryFieldArray([
      ...copySummary.slice(0, insertIndex),
      newField,
      ...copySummary.slice(insertIndex),
    ]);
  };

  const removeProfileFieldData = (indexToRemove) => {
    if (indexToRemove) {
      const removePosition = parseInt(indexToRemove) - 1;
      setSummaryFieldArray((prevSummaryFieldArray) => {
        const copySummary = JSON.parse(JSON.stringify(prevSummaryFieldArray));
        copySummary.splice(removePosition, 1);
        return copySummary;
      });
      setAddField({
        fieldName: "",
        fieldPosition: "",
        fieldPermanentStatus: "",
      });
    }
  };

  const deletePrescriptionSetting = async (isRemove) => {
    try {
      const response = await Network().delete(
        "/doctor/deleteUserPrescriptionFields",
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200].includes(response.status) && isRemove) {
        removeProfileFieldData(addField.fieldPosition);
        setShowFieldModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddFieldChange = (event) => {
    const { name, value } = event.target;
    setAddField({
      ...addField,
      [name]: value,
    });
  };

  let isFieldValidate;

  const fieldValidation = () => {
    isFieldValidate = true;
    if (!addField.fieldName) {
      isFieldValidate = false;
      toast.warning(t("Please enter field name"));
      return;
    }
    if (!addField.fieldPosition && addField.fieldPosition === "") {
      isFieldValidate = false;
      toast.warning(t("Please select field position"));
      return;
    }
    if (addField.fieldPermanentStatus === "") {
      isFieldValidate = false;
      toast.warning(t("Please select field permanently"));
      return;
    }
    return isFieldValidate;
  };

  const CustomToolTip = ({ id, children, title }) => (
    <OverlayTrigger
      // delay={{ hide: 40000 }}
      overlay={
        <Tooltip id={id} className="prescription-tooltip">
          {title}
        </Tooltip>
      }
    >
      <span className="tooltip-circle">{children}</span>
    </OverlayTrigger>
  );

  const handleDocModel = (index) => {
    setOpenModel(!openModel);
    setDocIndex(index);
    if (!openModel) {
      viewPdf(allPdfDocument[index]?.path, allPdfDocument[index]?.fileType);
    } else {
      setDocURL("");
    }
  };

  const handlePreDoc = () => {
    viewPdf(
      allPdfDocument[docIndex - 1].path,
      allPdfDocument[docIndex - 1]?.fileType
    );
    setDocIndex(docIndex - 1);
  };
  const handleNextDoc = () => {
    viewPdf(
      allPdfDocument[docIndex + 1].path,
      allPdfDocument[docIndex + 1]?.fileType
    );
    setDocIndex(docIndex + 1);
  };
  const viewPdf = async (path, type) => {
    setLoading(true);
    const data = {
      url: path,
    };

    const response = await documentView(data);
    setDocURL({ view: response.data, original: path, type: type });
    setLoading(false);
  };

  $("#rsd__select-day option:first-child").text(t("Day"));
  $("#rsd__select-month option:first-child").text(t("Month"));
  $("#rsd__select-year option:first-child").text(t("Year"));

  return (
    <>
      <GeneratePdf
        generateUpdateModal={generateUpdateModal}
        handleClose4={handleClose4}
        meetingId={props.meetingId}
        handleClose={props.handleClose}
        payloadData={payloadData}
        typing={typing}
        rxMeetingId={rxMeetingId}
        setRxMeetingId={setRxMeetingId}
      />
      {showTemplateModal ? (
        <AddTemplateModal
          showTemplateModal={showTemplateModal}
          handleTemplateChange={handleTemplateChange}
          addTemplate={addTemplate}
          handleTemplateSubmit={handleTemplateSubmit}
          handleMultiChange={handleMultiChange}
          templateList={templateList}
          handleEditTemplate={handleEditTemplate}
          summaryFieldArray={summaryFieldArray}
          isEditTemp={isEditTemp}
          submitEditTemplate={submitEditTemplate}
          setTemplateId={setTemplateId}
          setDeleteTempModal={setDeleteTempModal}
          deleteTempModal={deleteTempModal}
          handleCloseTempModal={handleCloseTempModal}
          handleAddTemplate={handleAddTemplate}
          templateId={templateId}
        />
      ) : null}

      {deleteTempModal ? (
        <DeleteTemplateModal
          deleteTempModal={deleteTempModal}
          setDeleteTempModal={setDeleteTempModal}
          handleDeleteTemplate={handleDeleteTemplate}
          handleCloseDeleteTemplate={handleCloseDeleteTemplate}
        />
      ) : null}

      {showSearch && (
        <TemplateSearchList
          filteredTemplate={filteredTemplate}
          handleSelectTemp={handleSelectTemp}
          showSearch={showSearch}
          setShowSearch={setShowSearch}
        />
      )}

      {showFieldModal ? (
        <AddFieldModal
          showFieldModal={showFieldModal}
          handleCloseFieldModal={handleCloseFieldModal}
          handleAddFieldChange={handleAddFieldChange}
          addField={addField}
          handleFieldSubmit={handleFieldSubmit}
          setDeleteFieldModal={setDeleteFieldModal}
          summaryFieldArray={summaryFieldArray}
          deleteFieldModal={deleteFieldModal}
        />
      ) : null}
      {deleteFieldModal ? (
        <DeleteFieldModal
          deleteFieldModal={deleteFieldModal}
          setDeleteFieldModal={setDeleteFieldModal}
          deletePrescriptionSetting={deletePrescriptionSetting}
          additionalFieldData={addField}
          removeProfileFieldData={removeProfileFieldData}
          setShowFieldModal={setShowFieldModal}
        />
      ) : null}

      {openModel ? (
        <AllDocumentModel
          openModel={openModel}
          handleClose={handleDocModel}
          docURL={docURL}
          handlePreDoc={handlePreDoc}
          handleNextDoc={handleNextDoc}
          documentLength={allPdfDocument?.length}
          docIndex={docIndex}
          loading={loading}
        />
      ) : null}
      <div className="user-create-section">
        <Form>
          <div
            className={`${"confirmation-agree-section"} ${
              lang === "EN" ? "textleft" : "textright"
            }`}
          >
            <>
              <div className="confirmation-input-section">
                <Form.Group className="form-group">
                  <Form.Control
                    name="id"
                    type="text"
                    onChange={handleChange}
                    id="id"
                    placeholder={t("Id Number")}
                    value={formValue.id}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    name="first_name"
                    type="text"
                    onChange={handleChange}
                    id="first_name"
                    placeholder={t("First Name")}
                    value={formValue.first_name}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    name="last_name"
                    type="text"
                    onChange={handleChange}
                    id="last_name"
                    placeholder={t("Last Name")}
                    value={formValue.last_name}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    name="email"
                    type="text"
                    onChange={handleChange}
                    id="email"
                    placeholder={t("Email")}
                    value={formValue.email}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Control
                    name="phone"
                    type="text"
                    onChange={handleChange}
                    id="phone"
                    className="phone_number"
                    placeholder={t("Phone Number")}
                    value={formValue.phone}
                    pattern="[+0-9 -]+"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <div className="choose-gender-grid">
                    <Form.Check
                      type={"radio"}
                      label={t("Male")}
                      name="clientGender"
                      value="Male"
                      onChange={handleChange}
                      checked={formValue.clientGender === "Male"}
                    />
                    <Form.Check
                      type={"radio"}
                      label={t("Female")}
                      name="clientGender"
                      value="Female"
                      onChange={handleChange}
                      checked={formValue.clientGender === "Female"}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label> {t("Date of Birth")} *</Form.Label>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => setCleared(true),
                        },
                      }}
                      views={["year", "month", "day"]}
                      onChange={onDateChange}
                      value={value}
                      disableFuture
                      format="DD/MM/YYYY"
                      inputFormat="DD/MM/YYYY"
                      className="rx-dob"
                      // openTo="year"
                    />
                  </LocalizationProvider>
                </Form.Group>
                {doctorData?.consultationSpeciality &&
                doctorData?.consultationSpeciality.length > 1 ? (
                  <Form.Group>
                    <Form.Label> {t("Choose Consultation field")} *</Form.Label>
                    <Form.Select
                      name="consultId"
                      onChange={handleChangeConsultId}
                      aria-label="Default select example"
                    >
                      <option value="">{t("Select")}</option>
                      {consultField &&
                        consultField?.map((el) => (
                          <option
                            value={el?._id}
                            key={el?._id}
                            selected={el?._id === consultId}
                          >
                            {lang === "HE" ? el?.heb : el?.eng}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                ) : null}
              </div>
            </>
          </div>
        </Form>
      </div>
      {allPdfDocument?.length > 0 && (
        <div className="summaries-list-section">
          <h3 style={{ textAlign: "center" }}>{t("Earlier summaries")} </h3>
          <ul className="listdocument">
            {allPdfDocument
              ? allPdfDocument.map((fileName, i) => {
                  return (
                    <li key={i}>
                      <div className="row fileList">
                        <div className="col-md-6">
                          <p className="file-name">
                            {fileName?.type === "Summary"
                              ? `${
                                  t("Meeting summary from") +
                                  moment(fileName?.createdAt).format(
                                    "DD.MM.YYYY"
                                  )
                                }`
                              : fileName.name}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <div className="file-thumbnail">
                            <img
                              src={
                                fileName?.fileType === "pdf" ||
                                fileName?.fileType === "application/pdf"
                                  ? pdfIcon
                                  : imageIcon
                              }
                              alt="thumbnail"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <Button
                            className="view-doc-pdf"
                            onClick={() => handleDocModel(i)}
                          >
                            {t("View")}
                          </Button>
                        </div>
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      )}
      <div className="inner-summary">
        <>
          {key == "Summary" ? (
            <>
              <div className="toggleDiv">
                <div className="tab-grid">
                  <span
                    onClick={() => {
                      setKey("Summary");
                    }}
                    className={key == "Summary" ? "spanActive" : "toggle "}
                  >
                    {t("Summary")}
                  </span>
                  <span
                    onClick={() => {
                      setKey("Prescription");
                    }}
                    className={key == "Prescription" ? "spanActive" : "toggle "}
                  >
                    {t("Prescription")}
                  </span>
                </div>
              </div>
              <div className="add-template-btn">
                <Button onClick={() => setShowTemplateModal(true)}>
                  {t("Templates settings")}
                </Button>{" "}
                <CustomToolTip
                  title={t(
                    "To retrieve the template press # in the relevant field, the list of templates you have defined for that field will appear"
                  )}
                  id="templateSetting"
                >
                  {" "}
                  ?{" "}
                </CustomToolTip>
              </div>
              <Form className="summarytext">
                {summaryFieldArray.map((field, i) => {
                  return (
                    <Form.Group
                      key={i}
                      className={
                        addField.fieldName === field.name &&
                        addField.fieldPermanentStatus === false
                          ? "hide-field"
                          : ""
                      }
                    >
                      <h3>{field?.label}</h3>
                      <textarea
                        type="text"
                        rows={3}
                        onChange={(e) =>
                          handleSummarychange(e, `${field?.name}`)
                        }
                        value={summary[field?.name]}
                        placeholder={field?.label}
                        className="form-control summary_text"
                      />
                    </Form.Group>
                  );
                })}

                {/* Old code start */}
                {/* <Form.Group>
                  <h3> {t("Reason for referral")}</h3>
                  <textarea
                    type="text"
                    rows={3}
                    onChange={(e) => handleSummarychange(e, "referral_reason")}
                    value={summary?.referral_reason}
                    placeholder={t("Reason for referral")}
                    className="form-control summary_text"
                  />
                </Form.Group>
                <Form.Group>
                  <h3> {t("Background Details")}</h3>
                  <textarea
                    type="text"
                    rows={3}
                    onChange={(e) =>
                      handleSummarychange(e, "background_details")
                    }
                    value={summary?.background_details}
                    placeholder={t("Background Details")}
                    className="form-control summary_text"
                  />
                </Form.Group>
                <Form.Group>
                  <h3> {t("Diagonosis")}</h3>
                  <textarea
                    type="text"
                    rows={3}
                    onChange={(e) => handleSummarychange(e, "diagnsis")}
                    value={summary?.diagnsis}
                    placeholder={t("Diagonosis")}
                    className="form-control summary_text"
                  />
                </Form.Group>
                <Form.Group>
                  <h3> {t("Treatment Program")}</h3>
                  <textarea
                    type="text"
                    rows={3}
                    onChange={(e) =>
                      handleSummarychange(e, "treatment_program")
                    }
                    value={summary?.treatment_program}
                    placeholder={t("Treatment Program")}
                    className="form-control summary_text"
                  />
                </Form.Group> */}
                {/* Old code end */}

                {props.checkIDF &&
                props.checkIDF === "640ed7103f08dcee2d1f1719" ? (
                  <Form.Group className="frequency">
                    <h3> {t("Continuing Care")}</h3>
                    <select
                      className="form-control"
                      name="continuingCare"
                      onChange={(e) => handleSummarychange(e, "continuingCare")}
                      autoComplete="off"
                    >
                      <option value="">{t("Select")}</option>

                      {continuingCare.map((d, i) => {
                        return (
                          <option
                            key={i + 1}
                            value={d}
                            selected={
                              summary?.continuingCare?.trim() === d.trim()
                            }
                          >
                            {d}
                          </option>
                        );
                      })}
                    </select>
                  </Form.Group>
                ) : null}
              </Form>
            </>
          ) : (
            <>
              <div className="toggleDiv">
                <div className="tab-grid">
                  <span
                    onClick={() => {
                      setKey("Summary");
                    }}
                    className={key == "Summary" ? "spanActive" : "toggle "}
                  >
                    {t("Summary")}
                  </span>
                  <span
                    onClick={() => {
                      setKey("Prescription");
                    }}
                    className={key == "Prescription" ? "spanActive" : "toggle "}
                  >
                    {t("Prescription")}
                  </span>
                </div>
              </div>
              <Form className="form_sammary_press">
                {prescriptionData.length != 0 ? (
                  prescriptionData.map((data, i) => {
                    return (
                      <div className="form_sammary_field" key={i}>
                        <h2> {t("Update prescription")}</h2>
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            className="medicine-row"
                          >
                            <Form.Group className="frequency medicine-select">
                              <Form.Label> {t("Medicine Name")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="medcineList"
                                placeholder="Select"
                                onChange={(e) =>
                                  handleMedicine(e.target.value, "inputVal", i)
                                }
                                onKeyDown={searchMedicine}
                                onFocus={() => handleListModal(i)}
                                value={data?.name || medicineValue?.Name}
                                className={`${
                                  errors[i]?.indexOf("name") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                autoComplete="off"
                              />
                              {i === selectedSec &&
                              medicineList &&
                              medicineList.length ? (
                                <ul className="auto-complete" ref={wrapperRef}>
                                  {medicineList.map((el) => (
                                    <li
                                      className="auto-complete-list"
                                      value={el.name}
                                      key={el._id}
                                      onClick={() =>
                                        handleMedicine(el, "listVal", i)
                                      }
                                      name="subcategory"
                                    >
                                      {el.Name}
                                    </li>
                                  ))}
                                </ul>
                              ) : medicineList && !medicineList.length ? (
                                <ul className="auto-complete" ref={wrapperRef}>
                                  <li>No Results Found</li>
                                </ul>
                              ) : null}
                            </Form.Group>
                            <Form.Group className="frequency">
                              <Form.Label>
                                {" "}
                                {t("Quantity")}{" "}
                                <CustomToolTip
                                  title={t(
                                    "Quantity: the number of packs of the medicine"
                                  )}
                                  id="quantity"
                                >
                                  {" "}
                                  ?{" "}
                                </CustomToolTip>{" "}
                              </Form.Label>

                              <Select
                                className={` ${
                                  errors[i]?.indexOf("quantity") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                classNamePrefix="prescription"
                                value={
                                  data?.quantity
                                    ? {
                                        value: data?.quantity,
                                        label: data?.quantity,
                                      }
                                    : ""
                                }
                                isRtl={lang === "HE"}
                                noOptionsMessage={() =>
                                  t("Type a number between 1-10")
                                }
                                styles={customStyles}
                                isSearchable={true}
                                options={quantityArray}
                                name="quantity"
                                placeholder="Select"
                                onChange={(e) =>
                                  handleinputchange(
                                    {
                                      target: {
                                        name: "quantity",
                                        value: e.value,
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </div>

                          <h2> {t("Instruction of use")}</h2>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Group className="frequency new-field">
                              <Form.Label>
                                {" "}
                                {t("Dosage")}{" "}
                                <CustomToolTip
                                  title={t(
                                    "Dosage: The number of units to be taken for each occasion of taking the medicine"
                                  )}
                                  id="dosage"
                                >
                                  ?{" "}
                                </CustomToolTip>{" "}
                              </Form.Label>

                              <Form.Control
                                className={` ${
                                  errors[i]?.indexOf("dosage") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                name="dosage"
                                onKeyDown={(e) => e.key === "-" && e.preventDefault()}
                                type="number"
                                min="0"
                                onChange={(e) => handleinputchange(e, i)}
                                id="dosage"
                                placeholder={t("Dosage")}
                                value={data?.dosage}
                                style={{ textAlign: lang === "HE" ? "right" : "left" }}
                              />
                            </Form.Group>
                            <Form.Group className="frequency new-field"/>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Group className="frequency">
                              <Form.Label>
                                {" "}
                                {t("Times")}{" "}
                                <CustomToolTip
                                  title={t(
                                    "The number of doses to be taken per unit of time according to the frequency in the next field"
                                  )}
                                  id="times"
                                >
                                  ?{" "}
                                </CustomToolTip>
                              </Form.Label>
                              <Select
                                className={` ${
                                  errors[i]?.indexOf("frequency") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                classNamePrefix="prescription"
                                value={
                                  data?.frequency
                                    ? {
                                        value: data.frequency,
                                        label: data.frequency,
                                      }
                                    : ""
                                }
                                isRtl={lang === "HE"}
                                placeholder="Select"
                                noOptionsMessage={() =>
                                  t("Type a number between 1-10")
                                }
                                styles={customStyles}
                                isSearchable={true}
                                options={timesArray}
                                name="frequency"
                                onChange={(e) =>
                                  handleinputchange(
                                    {
                                      target: {
                                        name: "frequency",
                                        value: e.value,
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="frequency">
                              <Form.Label>
                                {" "}
                                {t("Frequency")}{" "}
                                <CustomToolTip
                                  title={t(
                                    "The time unit as defined in relation to the number in the previous field"
                                  )}
                                  id="times"
                                >
                                  ?{" "}
                                </CustomToolTip>
                              </Form.Label>
                              <Select
                                className={` ${
                                  errors[i]?.indexOf("frequencyUnit") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                classNamePrefix="prescription"
                                value={
                                  data.frequencyUnit
                                    ? {
                                        value: t(data.frequencyUnit),
                                        label: t(data.frequencyUnit),
                                      }
                                    : ""
                                }
                                isRtl={lang === "HE"}
                                styles={customStyles}
                                isSearchable={true}
                                options={frequencyArray}
                                placeholder="Select"
                                name="frequencyUnit"
                                onChange={(e) =>
                                  handleinputchange(
                                    {
                                      target: {
                                        name: "frequencyUnit",
                                        value: e.value,
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Form.Group className="frequency">
                              <Form.Label>
                                {" "}
                                {t("Period")}{" "}
                                <CustomToolTip
                                  title={t(
                                    "For a period of: the number of days/weeks the patient has to repeat taking the medicine"
                                  )}
                                  id="times"
                                >
                                  ?{" "}
                                </CustomToolTip>{" "}
                              </Form.Label>
                              <Select
                                className={` ${
                                  errors[i]?.indexOf("duration") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                classNamePrefix="prescription"
                                value={
                                  data?.duration
                                    ? handlePeriodArray()[
                                        Number(data?.duration) - 1
                                      ]
                                    : null
                                }
                                isRtl={lang === "HE"}
                                noOptionsMessage={() =>
                                  t("Enter a number between 1-180")
                                }
                                styles={customStyles}
                                isSearchable={true}
                                options={handlePeriodArray()}
                                placeholder="Select"
                                name="duration"
                                onChange={(e) =>
                                  handleinputchange(
                                    {
                                      target: {
                                        name: "duration",
                                        value: e.value,
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                            <Form.Group className="frequency">
                              <Form.Label>
                                {" "}
                                {t("Duration for")}{" "}
                                <CustomToolTip
                                  title={t(
                                    "Duration: the unit of measure (day/week...) that refers to the previous field"
                                  )}
                                  id="times"
                                >
                                  ?{" "}
                                </CustomToolTip>
                              </Form.Label>
                              <Select
                                className={` ${
                                  errors[i]?.indexOf("durationUnit") > -1
                                    ? "red-border"
                                    : ""
                                }`}
                                classNamePrefix="prescription"
                                value={
                                  data?.durationUnit
                                    ? {
                                        value: t(data?.durationUnit),
                                        label: t(data?.durationUnit),
                                      }
                                    : ""
                                }
                                isRtl={lang === "HE"}
                                noOptionsMessage={() =>
                                  t("No option available")
                                }
                                styles={customStyles}
                                isSearchable={true}
                                options={durationArray}
                                placeholder="Select"
                                name="durationUnit"
                                autoComplete={true}
                                onChange={(e) =>
                                  handleinputchange(
                                    {
                                      target: {
                                        name: "durationUnit",
                                        value: e.value,
                                      },
                                    },
                                    i
                                  )
                                }
                              />
                            </Form.Group>
                          </div>


                          {/*==================== doctor and patient meta details for IsSam Start here ======================*/}

                          {medicMetaDetails[i] && (
                            <>
                              <h2> {t("Patient Details")}</h2>
                              {!showPatientInputField ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Form.Group className="frequency new-field">
                                      <Form.Label> {t("Street")} </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf("patient_street") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="patient_street"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="patient_street"
                                        placeholder={t("Street")}
                                        value={
                                          formValue?.patient_street ||
                                          data?.patient_street
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="frequency new-field">
                                      <Form.Label> {t("House No")} </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf(
                                            "patient_houseno"
                                          ) > -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="patient_houseno"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="patient_houseno"
                                        placeholder={t("House No")}
                                        value={
                                          formValue?.patient_houseno ||
                                          data?.patient_houseno
                                        }
                                      />
                                    </Form.Group>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Form.Group className="frequency new-field">
                                      <Form.Label> {t("City")} </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf("patient_city") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="patient_city"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="patient_city"
                                        placeholder={t("City")}
                                        value={
                                          formValue?.patient_city ||
                                          data?.patient_city
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group className="frequency new-field">
                                      <Form.Label>
                                        {" "}
                                        {t("Postal Code")}{" "}
                                      </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf(
                                            "patient_pincode"
                                          ) > -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="patient_pincode"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="patient_pincode"
                                        placeholder={t("Postal Code")}
                                        value={
                                          formValue?.patient_pincode ||
                                          data?.patient_pincode
                                        }
                                      />
                                    </Form.Group>
                                  </div>

                                  {/* <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Form.Group className="frequency new-field">
                                      <Form.Label>
                                        {" "}
                                        {t("Date of Birth")}{" "}
                                      </Form.Label>

                                      <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                      >
                                        <DatePicker
                                          slotProps={{
                                            field: {
                                              clearable: true,
                                              onClear: () => setCleared(true),
                                            },
                                          }}
                                          views={["year", "month", "day"]}
                                          onChange={(e) =>
                                            onMedicineMetaDOBChange(
                                              e,
                                              i,
                                              "patient_dob"
                                            )
                                          }
                                          value={
                                            data?.patient_dob
                                              ? data?.patient_dob
                                              : formValue?.clientDOB
                                          }
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          inputFormat="DD/MM/YYYY"
                                          className="rx-dob"
                                          // openTo="year"
                                        />
                                      </LocalizationProvider>
                                    </Form.Group>

                               <Form.Group className="frequency new-field">
                                      <Form.Label> {t("Gender")} </Form.Label>
                               

                                      <Select
                                        className={` ${
                                          errors[i]?.indexOf("patient_gender") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        classNamePrefix="prescription"
                                        defaultValue={
                                          data?.patient_gender
                                            ? {
                                                value: data?.patient_gender
                                                  ? data?.patient_gender
                                                  : formValue?.clientGender,
                                                label: data?.patient_gender
                                                  ? t(data?.patient_gender)
                                                  : t(formValue?.clientGender),
                                              }
                                            : ""
                                        }
                                        isRtl={lang === "HE"}
                                        noOptionsMessage={() =>
                                          t(
                                            "Please select your gender Male/Female"
                                          )
                                        }
                                        styles={customStyles}
                                        isSearchable={true}
                                        options={genderArr}
                                        name="patient_gender"
                                        placeholder="Select"
                                        onChange={(e) =>
                                          handleinputchange(
                                            {
                                              target: {
                                                name: "patient_gender",
                                                value: e.value,
                                              },
                                            },
                                            i
                                          )
                                        }
                                      />
                                    </Form.Group> 
                                  </div> */}
                                </>
                              ) : (
                                <Form.Group
                                  className="frequency"
                                  style={{ width: "100%", margin: "0px" }}
                                >
                                  <Form.Label>{t("Address")}</Form.Label>

                                  <div
                                    className={`doctor-address ${
                                      lang === "EN" ? "eng-text" : ""
                                    }`}
                                  >
                                    <Form.Label>
                                      {combinePatientAddress}
                                    </Form.Label>
                                    <Button
                                      onClick={() =>
                                        handlePatientEditInputField()
                                      }
                                    >
                                      {t("Edit")}
                                    </Button>
                                  </div>
                                </Form.Group>
                              )}

                              <h2> {t("Doctor Details")}</h2>

                              {!showInputField ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Form.Group className="frequency new-field">
                                      <Form.Label> {t("Street")} </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf("doctor_street") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="doctor_street"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="doctor_street"
                                        placeholder={t("Street")}
                                        defaultValue={
                                          medicineMetaDocData?.clientAddress
                                            ?.street
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group className="frequency new-field">
                                      <Form.Label> {t("House No")} </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf("doctor_houseno") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="doctor_houseno"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="doctor_houseno"
                                        placeholder={t("House No")}
                                        defaultValue={
                                          medicineMetaDocData?.clientAddress
                                            ?.house
                                        }
                                      />
                                    </Form.Group>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Form.Group className="frequency new-field">
                                      <Form.Label> {t("City")} </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf("doctor_city") > -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="doctor_city"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="doctor_city"
                                        placeholder={t("City")}
                                        defaultValue={
                                          medicineMetaDocData?.clientAddress
                                            ?.city
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group className="frequency new-field">
                                      <Form.Label>
                                        {" "}
                                        {t("Postal Code")}{" "}
                                      </Form.Label>
                                      <Form.Control
                                        className={` ${
                                          errors[i]?.indexOf("doctor_pincode") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="doctor_pincode"
                                        type="text"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        id="doctor_pincode"
                                        placeholder={t("Postal Code")}
                                        defaultValue={
                                          medicineMetaDocData?.clientAddress
                                            ?.pincode
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Form.Group className="frequency new-field">
                                      <Form.Label>
                                        {" "}
                                        {t("Clinic phone number")}{" "}
                                      </Form.Label>
                                      <Form.Control
                                        className={`phone_number ${
                                          errors[i]?.indexOf("doctor_phone") >
                                          -1
                                            ? "red-border"
                                            : ""
                                        }`}
                                        name="doctor_phone"
                                        type="text"
                                        id="doctor_phone"
                                        placeholder={t("Clinic phone number")}
                                        pattern="[+0-9 -]+"
                                        onChange={(e) =>
                                          handleinputchange(e, i)
                                        }
                                        value={data?.doctor_phone}
                                      />
                                    </Form.Group>
                                  </div>
                                </>
                              ) : (
                                <Form.Group
                                  className="frequency ddd"
                                  style={{ width: "100%", margin: "0px" }}
                                >
                                  <Form.Label>{t("Address")}</Form.Label>

                                  <div
                                    className={`doctor-address ${
                                      lang === "EN" ? "eng-text" : ""
                                    }`}
                                  >
                                    <Form.Label>{combineAddress}</Form.Label>
                                    <Button
                                      onClick={() => handleEditInputField()}
                                    >
                                      {t("Edit")}
                                    </Button>
                                  </div>
                                </Form.Group>
                              )}
                            </>
                          )}

                          {/* ################### doctor and patient meta details IsSam End here ######################*/}

                          <div className="abc">
                            <input
                              type="checkbox"
                              defaultChecked={data?.usedOnRegularBasis}
                              name="usedOnRegularBasis"
                              onChange={(e) => handleinputchange(e, i)}
                            />
                            <span>
                              {" "}
                              {t(
                                "This patient uses the drag on a regualer basis"
                              )}
                            </span>
                          </div>
                          <div className="abc">
                            <input
                              defaultChecked={data?.chronic}
                              type="checkbox"
                              name="chronic"
                              onChange={(e) => handleinputchange(e, i)}
                            />
                            <span> {t("The drag is cronic")}</span>
                          </div>
                          <Form.Group>
                            <Form.Label> {t("Remarks")}</Form.Label>
                            <textarea
                              className="form-control"
                              id="remarks"
                              name="remarks"
                              defaultValue={data ? data.remarks : ""}
                              onChange={(e) => handleinputchange(e, i)}
                              placeholder={t("Remarks")}
                              autoComplete="off"
                            />
                          </Form.Group>
                          <br></br>
                          {prescriptionData.length - 1 === i && (
                            <Button
                              onClick={() => handleaddclick()}
                              className="buttton_content"
                              tabIndex={-1}
                            >
                              {t("Add More")}
                            </Button>
                          )}
                          {prescriptionData.length != 0 && (
                            <Button
                              variant="danger"
                              className="removebuttton_content"
                              onClick={() => handleremove(data)}
                              style={{ margin: " 0 10px" }}
                              tabIndex={-1}
                            >
                              {t("Remove")}
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Button
                    onClick={() => handleaddclick()}
                    className="buttton_content"
                    tabIndex={-1}
                  >
                    {t("Add New")}
                  </Button>
                )}
              </Form>
            </>
          )}
          <div className="two_button">
            <div className="two_button-left">
              <Button
                variant="primary"
                size="lg"
                className="buttton_content save_sammary_press"
                onClick={signandsend}
                tabIndex={-1}
                disabled={
                  (key === "Prescription" && prescriptionData?.length === 0) ||
                  (key === "Summary" && !isNewValue)
                }
              >
                {t("Sign and send summary to patient")}
              </Button>
              {key === "Summary" ? (
                <>
                  {summaryFieldArray.length < 5 ? (
                    <Button
                      variant="primary"
                      size="lg"
                      className="buttton_content save_sammary_press"
                      onClick={() => handleFieldModal()}
                    >
                      {t("Add Field")} <AddIcon />
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="primary"
                        size="lg"
                        className="buttton_content save_sammary_press"
                        onClick={() => handleFieldModal()}
                      >
                        {t("Edit Field")} <EditIcon />
                      </Button>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default RxSummaryComponent;
